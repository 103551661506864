import "./Rating.scss";

const Rating = ({ name, rating }) => {
    return (
        <>
            <div className={rating <= 39.9 ? "rage__item is-red" : rating >= 70 ? "rage__item is-green" : "rage__item is-yellow"}>
                <div className="rage__name">{name.replace(/^\w/, (c) => c.toUpperCase())}</div>
                <div className="rage__val">
                    <span className="rage__type-1">{rating} /</span>
                    <span className="rage__type-2">100</span>
                </div>
            </div>
        </>
    )
}

export default Rating;
