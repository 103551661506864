import {useState} from "react";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import authLogo from "../../assets/img/Union.svg";

import rpc from "../../services/rpc";
import {isLoggedSelector} from "../../states/session";

import "./Login.scss"

const Login = () => {
    const setIsLoggedIn = useSetRecoilState(isLoggedSelector);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get("email");
        const password = data.get("password");
        try {
            const {token} = await rpc.login(email, password);
            setIsLoggedIn(token);
        } catch (err) {
            // eslint-disable-next-line no-console
            // TODO: popup
            // console.log("ERROR", err);
            setIsLoggedIn(false);
        }
        setLoading(false);
    };
    const {t} = useTranslation("login");
    return (
        <Container className="main" maxWidth="xs">
            <Box className="logo"
                sx={{flexDirection: "column"}}
            >
                <img src={authLogo} alt="Логотип" className="logo"/>
                <p id="A">ECODISCLOSURE</p>
                <p id="B">Eco-disclosure & scoring centre</p>

                <Box className="registration_form"
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <input
                        id="email"
                        name="email"
                        type="text"
                        defaultValue="master@eco-disclosure.com"
                        autoFocus
                        className="login"
                        placeholder={t("Email Address")}/>

                    <input
                        name="password"
                        type="password"
                        id="password"
                        className="login"
                        placeholder={t("Password")}/>
                    {(loading) ?
                        <LoadingButton
                            id="button"
                            type="submit"
                            disabled
                        >
                            {t("Signing...")}
                        </LoadingButton> :
                        <LoadingButton
                            id="button"
                            type="submit"
                        >
                            {t("Sign In")}
                        </LoadingButton>
                    }

                </Box>

                <div className="text_help">
                    <a id="link" href="help@ecodisclosure.com">help@ecodisclosure.com </a>
                </div>
            </Box>
        </Container>
    )
}

export default Login;
