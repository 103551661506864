import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import Roboto from "../../assets/fonts/Roboto.ttf";

import logo from "../../assets/img/report-logo.png";

Font.register({ family: "Roboto", src: Roboto });

const styles = StyleSheet.create({
  page: {
    color: "#0B0B0B",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  header: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "120px",
  },
  logo: {
    boxShadow: 0,
    textAlign: "center",
  },
  governanceContainer: {
    marginTop: "18px",
    marginLeft: "4%",
    marginRight: "4%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  governanceTitle: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "22px",
  },
  governanceInfo: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    marginRight: "15px",
  },
  governanceParagraph: {
    marginBottom: "20px",
  },
  governanceParagraphTitle: {
    paddingBottom: "7px",
  },
  governanceParagraphIndicators: {
    marginBottom: "10px",
    color: "#A1A5AE",
  },
  governanceParagraphData: {
    color: "#0B0B0B",
  },
  governanceCards: {
    display: "flex",
    flexDirection: "column",
    width: "38%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: "40px",
    marginLeft: "6px",
  },
  governanceCard: {
    borderRadius: "8px",
    width: "216px",
    height: "96px",
    marginBottom: "24px",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  greenCard: {
    backgroundColor: "#00C614",
  },
  redCard: {
    backgroundColor: "#FF0000",
  },
  yellowCard: {
    backgroundColor: "#FFB800",
  },
  indexTitle: {
    margin: "16px",
    marginBottom: "13px",
  },
  index: {
    fontSize: "16px",
    marginLeft: "16px",
    marginBottom: "16px",
  },
  indexText: {
    fontSize: "12px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    color: "#A1A5AE",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pagesLeft: {
    color: "#0B0B0B",
  },
});

const GovernancePage = ({ company }) => {
  return (
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.image} src={logo} />
      </View>
      <View style={styles.governanceContainer}>
        <View style={styles.governanceInfo}>
          <Text style={styles.governanceTitle}>G - GOVERNANCE</Text>
          <View style={styles.governanceParagraph}>
            <Text style={styles.governanceParagraphTitle}>
              Corporate Governance
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Internal financial control body
              <Text style={styles.governanceParagraphData}>
                {" "}
                Audit Committee
              </Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Corporate Secretary
              <Text style={styles.governanceParagraphData}> Yes</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Risk Management Business Units
              <Text style={styles.governanceParagraphData}> Yes</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Internal Control Business Units
              <Text style={styles.governanceParagraphData}> Yes</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Composition of the Board of Directors (Supervisory Board)
              <Text style={styles.governanceParagraphData}> Yes</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Number of Women on the Board of Directors
              <Text style={styles.governanceParagraphData}> Yes</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Existence of the committee of the board of directors (supervisory
              board) on sustainable development/environment/social
              responsibility
              <Text style={styles.governanceParagraphData}>
                {" "}
                Sustainable Development Committee
              </Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Interaction with stakeholders
              <Text style={styles.governanceParagraphData}> 9/10</Text>
            </Text>
          </View>
          <View style={styles.governanceParagraph}>
            <Text style={styles.governanceParagraphTitle}>Risk Management</Text>
            <Text style={styles.governanceParagraphIndicators}>
              Does the company assess ESG risks (including when choosing
              counterparties)?
              <Text style={styles.governanceParagraphData}>
                {" "}
                Yes, 458 checks conducted in 2021
              </Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Do you consider ESG factors (environmental, social, and
              governance) when making investment decisions?
              <Text style={styles.governanceParagraphData}>
                {" "}
                Yes, considered
              </Text>
            </Text>
          </View>
          <View style={styles.governanceParagraph}>
            <Text style={styles.governanceParagraphTitle}>
              Compliance Management
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Compliance with antitrust legislation
              <Text style={styles.governanceParagraphData}> 10/10</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Measures taken to prevent cases of fraud and corruption
              <Text style={styles.governanceParagraphData}> 7/10</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Information security
              <Text style={styles.governanceParagraphData}> 8/10</Text>
            </Text>
            <Text style={styles.governanceParagraphIndicators}>
              Company {company.name} was held administratively liable for
              violations of corporate legislation in 2021
              <Text style={styles.governanceParagraphData}> not held</Text>
            </Text>
          </View>
        </View>
        <View style={styles.governanceCards}>
          <View style={[styles.governanceCard, styles.greenCard]}>
            <Text style={styles.indexTitle}>Disclosure Index (DI)</Text>
            <Text style={styles.index}>
              81<Text style={styles.indexText}> high</Text>
            </Text>
          </View>
          <View style={[styles.governanceCard, styles.greenCard]}>
            <Text style={styles.indexTitle}>Social Impact Index (SII)</Text>
            <Text style={styles.index}>
              72<Text style={styles.indexText}> high</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>
          © 2022 ECODISCLOSURE. Not for further distribution, for internal use
          only
        </Text>
        <Text style={styles.pagesLeft}>4/4</Text>
      </View>
    </Page>
  );
};

export default GovernancePage;
