import {useTranslation} from "react-i18next";
import { Link, useParams, useLocation } from "react-router-dom";
import logo from "../../../../assets/img/dark-logo.svg";
import "../../page-layout/PageLayout.scss";

const Links = ({ route }) => {
    const { id } = useParams();
    const { pathname } = useLocation();

    const {t} = useTranslation('navigations');

    return (
        <>
            <div className="header__left">
                <Link 
                    className="logo navigation-link" 
                    to={pathname === `/contractor/${id}` ? `/report/${route?.from.match(/\d+/)[0]}` : pathname === "/404" ? `/report/1` : `/report/${id}`}
                >
                    <img src={logo} 
                        alt="logo"
                        className="header__logo" />
                </Link>
            </div>
            {id? 
                <div className="header__main">
                    <div className="head-menu">
                        <div className="head-menu__list">
                            <Link 
                                className={pathname? pathname === `/report/${id}` ? "head-menu__link is-active" : "head-menu__link" : null} 
                                to={pathname === `/contractor/${id}` ? `/report/${route?.from.match(/\d+/)[0]}` : pathname === "/404" ? `/report/1` : `/report/${id}`}
                                >{t("Report")}
                            </Link>
                            <Link 
                                className={pathname? pathname === `/contractors/${id}` ? "head-menu__link is-active" : "head-menu__link" : null} 
                                to={pathname === `/contractor/${id}` ? `contractors/${route?.from.match(/\d+/)[0]}` :  pathname === "/404" ? `/contractors/1` : `/contractors/${id}`}
                                >{t("Contractors")}
                            </Link>
                        </div>
                    </div>
                </div>
            :
                <div className="header__main">
                    <div className="head-menu">
                        <div className="head-menu__list">
                            <Link className="head-menu__link" onClick={e => e.preventDefault()} to="#">{t("Report")}</Link>
                            <Link className="head-menu__link" onClick={e => e.preventDefault()} to="#">{t("Contractors")}</Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Links;
