import {useState} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import rpc from "../../../../services/rpc";
import {isLoggedSelector} from "../../../../states/session";
import Links from "./Links";
import LanguageSwitcher from "../../language/LanguageSwitcher";
import "../../page-layout/PageLayout.scss";
import "./HeaderNav.scss";
import notifActiveIcon from "../../../../assets/img/notification.svg";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {backgroundColor: alpha(theme.palette.common.white, 0.25)},
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

const Navigation = ({ route, notifications }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const setIsLoggedIn = useSetRecoilState(isLoggedSelector);

    const {t} = useTranslation("navigations");

    const logout = async () => {
        await rpc.logout()
        setIsLoggedIn(false);
    }

    const searchQueryCapture = (event) => {
        setSearchQuery(event.target.value)
    }
    const searchSubmit = (event) => {
        const currentQuery = searchParams.get("q")
        if (event.key === "Enter" && currentQuery !== searchQuery) {
            setSearchParams(`q=${searchQuery}`)
            navigate(`/search?q=${searchQuery}`)
        }
    }

    return (
        <div className="header">
            <Links route={route} />
                {/* <Search sx={{pl:2}}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        fullWidth={true}
                        placeholder={t("Search contractor …")}
                        defaultValue={searchQuery}
                        onChange={searchQueryCapture}
                        onKeyPress={searchSubmit}
                        color={"white"}
                    />
                </Search> */}
                <div className="header__controls">
                    <LanguageSwitcher />
                    {notifications.length > 0 ?
                        <>
                            <div className="header__btn icon-1k_note"></div>
                            <img className="notification-active" src={notifActiveIcon} alt="notification active button" />
                        </>
                    :
                        <div className="header__btn icon-1k_note"></div>
                    }
                    <div className="header__btn icon-1l_profile" onClick={logout}></div>
                </div>
        </div>
    )
}

export default Navigation;
