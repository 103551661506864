import {restoreFromStorage} from "./storage";

let baseURL = "https://lk.ecodisclosure.com/rpc/v0"
let signinURL = "https://lk.ecodisclosure.com/signin/v0"

if (process.env.REACT_APP_LOCAL_BACKEND === "true") {
    baseURL = "http://0.0.0.0:8000/rpc/v0"
    signinURL = "http://0.0.0.0:8000/signin/v0"
}

const timer = ms => new Promise(res => setTimeout(res, ms))

const delayCalculator = () => {
    const minDelay = 200    // ms -> 0.2 seconds
    const maxDelay = 10000  // ms -> 10 seconds
    const factor = 1.5
    const jitter = 0.1
    let delay = minDelay
    return () => {
        delay = Math.min(delay * factor, maxDelay)
        delay = delay + (delay * jitter)
        return delay
    }
}

const retry = async (func, n) => {
    const getDelay = delayCalculator()
    for (let i = 0; i < n; i++) {
        try {
            return await func();
        } catch (err) {
            let delay = getDelay()
            const isLastAttempt = i === n-1;
            if (isLastAttempt) throw err;

            await timer(delay);
        }
    }
};

const fetchRPC = async (method, payload, token) => {
    let headers = { "Content-Type": "application/json" };
    if (token !== "") {
        headers.Token = token;
    }
    const body = JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: method,
        params: payload,
    })
    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: headers,
        body: body,
    };
    let url;
    if (method === "login") {
        url = signinURL
    } else {
        url = baseURL
    }

    const response = await retry(()=> fetch(url, requestOptions), 5);
    return response.json();
};

let rpc = {
    async login(email, password) {
        const response = await fetchRPC("login", {email:email, password:password})
        if (response.error) {
            throw response.error.message
        }
        const token = response.result
        return {
            token: token,
        }
    },
    async logout() {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("logout", {}, token)
        if (response.error) {
            console.log(response)  // TODO: remaster
        }
    },
    async refreshToken() {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("refreshToken", {}, token)
        if (response.error) {
            throw response.error.message
        }
        const newToken = response.result
        return {
            token: newToken,
        }
    },
    async getNavigations() {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("getNavigations", {}, token)
        if (response.error) {
            throw response.error.message
        }
        return response.result
    },
    async getSearchResults(query) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("searchContractors", {query: query}, token)
        if (response.error) {
            throw response.error.message
        }
        return response.result
    },
    async getContractors(company_id) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("getContractors", {company_id: company_id}, token)
        if (response.error) {
            throw response.error.message
        }
        return response.result
    },
    async getCompany(company_id) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("getCompany", {company_id: company_id}, token)
        if (response.error) {
            throw response.error.message
        }
        return {
            company: response.result,
        }
    },
    async getDocuments(company_id) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("listDocuments", {company_id: company_id}, token)
        if (response.error) {
            throw response.error.message
        }
        return {
            documents: response.result,
        }
    },
    async getFeed(company_id) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("getFeed", {company_id: company_id}, token)
        if (response.error) {
            throw response.error.message
        }
        return {
            feed: response.result,
        }
    },
    async getContractor(contractor_id) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("getContractor", {contractor_id: contractor_id}, token)
        if (response.error) {
            throw response.error.message
        }
        return {
            contractor: response.result,
        }
    },
    async uploadDocuments(company_id, documents) {
        let data = {};

        const readFileAsync = async (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })
        }

        for (let idx = 0; idx <documents.length; idx++) {
            const file = documents[idx]
            let contentBuffer = await readFileAsync(file)
            data[`file_${idx}`] = {
                "filename": file.name,
                "blob": contentBuffer,
            };
        }
        const token = restoreFromStorage("token")
        const response = await fetchRPC(
            "uploadDocuments",
            {company_id: company_id, data: data},
            token
        )
        if (response.error) {
            throw response.error.message
        }
        return {
            result: response.result,
        }
    },
    async requestDocuments(contractorId, documents) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC(
            "requestDocuments",
            {contractor_id: contractorId, documents: documents},
            token
        )
        if (response.error) {
            throw response.error.message
        }
        return {
            result: response.result,
        }
    },
    async deleteDocument(company_id, key) {
        const token = restoreFromStorage("token")
        const response = await fetchRPC("deleteDocument", {company_id: company_id, key: key}, token)
        if (response.error) {
            throw response.error.message
        }
        return {
            result: response.result,
        }
    }
}

export default rpc;