import {useTranslation} from "react-i18next";

import Background404 from "../../assets/img/404.svg";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Item = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const NotFound = () => {
    const {t} = useTranslation('common');

    return (
        <Stack
            spacing={2}
            sx={{height: 1}}
            align="justify"
        >
            <Item sx={{mt: 10}}>
                <img
                    src={Background404}
                    alt={t("404 - Page Not Found")}
                    width="50%"
                    height="50%"
                />
            </Item>
            <Item style={{marginBottom: "110vh"}}>
                <Typography component={'span'} sx={{fontSize: 22}}>
                    {t("Page not found")}
                </Typography>
            </Item>
        </Stack>
    )
}

export default NotFound;