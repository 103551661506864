import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../../../scss/PageBlock.scss";
import NoData from "./no-data/NoData";
import Tooltip from "@mui/material/Tooltip";

const RatingTable = ({rows}) => {
    const navigate = useNavigate();
    const navigateToContractor = (contractor_id) => {
        navigate(`/contractor/${contractor_id}`);
    }

    const {t} = useTranslation('common');

    return (
        <>
            {(rows.length > 0)?
                <>
                    <table className="block-stat__table">
                        <tbody>
                            <tr>
                                <th>{t("Contractor")}</th>
                                <th>environmental</th>
                                <th>social</th>
                                <th>governance</th>
                            </tr>
                                {rows.map((row) => (
                                    <Tooltip key={row.name} title={t("Double click to see the report")} placement="left" arrow>
                                        <tr className="rating-name" onDoubleClick={()=>navigateToContractor(row.company_id)}>
                                            <td>{row.name}</td>
                                            <td>{row.environmental}</td>
                                            <td>{row.social}</td>
                                            <td>{row.governance}</td>
                                        </tr>
                                    </Tooltip>
                                ))}
                        </tbody>
                    </table>
                </>:
                <NoData>
                    <p className="no-data__title">{t("There is no information about contractors")}</p>
                </NoData>
            }
        </>
    )
}

export default RatingTable;
