import React from "react";
import ReactDOM from "react-dom";
import {RecoilRoot} from "recoil";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_ru from "./translations/ru/common.json";
import login_ru from "./translations/ru/login.json";
import navigations_ru from "./translations/ru/navigations.json";
import recommendations_ru from "./translations/ru/recommendations.json"
import documents_ru from  "./translations/ru/documents.json"

export const defaultLanguage = "ru"

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: defaultLanguage,                              // language to use
    resources: {
        ru: {
            common: common_ru,
            login: login_ru,
            navigations: navigations_ru,
            recommendations: recommendations_ru,
            documents: documents_ru,
        },
    },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
