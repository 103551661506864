import {useTranslation} from "react-i18next";
import "./Recommendations.scss";

const Recommendations = () => {
    const {t} = useTranslation('recommendations');

    // const mapping = {
    //     // Environmental
    //     201: t("Improve environmental management programs"),
    //     202: t("Increase the degree of disclosure of environmental information"),
    //     203: t("Develop and implement new air protection programs"),
    //     204: t("Reduce greenhouse gas emissions"),
    //     205: t("Improve atmospheric air protection programs"),
    //     206: t("Develop and implement new programs to reduce production waste"),
    //     207: t("Develop and implement new energy efficiency programs"),
    //     208: t("Take additional measures for due diligence and ESG risk assessment when choosing a counterparty"),
    //     209: t("Develop and implement new programs in the field of sustainable development and corporate social responsibility"),
    //     210: t("Develop and implement new programs to improve the greening of the workspace"),
    //     211: t("Take measures to prevent pollution and restore the territories of the company's activities"),
    //     212: t("Introduce the use of renewable energy sources"),
    //     // Social
    //     501: t("Develop and implement additional employee support programs"),
    //     502: t("Take measures to prevent discrimination of employees"),
    //     503: t("Develop and implement new occupational health and safety programs at the workplace"),
    //     504: t("Conduct additional safety training for employees"),
    //     505: t("Develop and implement additional programs for the protection of human rights"),
    //     506: t("Conduct additional activities to work with indigenous peoples and local communities affected by the company's activities"),
    //     507: t("Take measures to prevent the negative impact of the company on local communities"),
    //     508: t("Take additional measures for due diligence and ESG risk assessment when choosing a counterparty and increase the number of cooperation programs with social entrepreneurs"),
    //     // Governance
    //     801: t("Implement the position of corporate secretary"),
    //     802: t("Implement a business unit or an authorized risk management person"),
    //     803: t("Implement a business unit or an authorized person for internal control"),
    //     804: t("Increase the number of women on the board of directors"),
    //     805: t("Take measures to improve interaction with stakeholders"),
    //     806: t("Develop and implement additional programs on antimonopoly activities and take measures to prevent violations of antimonopoly legislation"),
    //     807: t("Develop and implement additional information security programs and take measures to prevent unauthorized access to confidential information"),
    //     808: t("Take additional measures to prevent cases of fraud, corruption"),

    //     "---": "---",
    // }

    return (
        <div className="flex-block">
            <div className="flex-block__block">
                <div className="block-stat large">
                    <div className="block-stat__head is-flex is-space">
                        <div className="block-stat__title">{t("Score indicators")}</div>
                    </div>
                    <div className="table-c-block">
                        <div className="table-c-block__bg is-green"></div>
                        <div className="table-c-block__bg is-brown"></div>
                        <div className="table-c-block__bg is-blue"></div>
                        <table className="table-c-block__table">
                            <thead>
                                <tr>
                                    <th>{t("Title")}</th>
                                    <th className="is-upper">{t("Positive")}</th>
                                    <th className="is-upper">{t("Negative")}</th>
                                    <th className="is-upper">{t("Recommendations")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="2">Environmental</td>
                                    <td>Eco management</td>
                                    <td>Energy efficiency</td>
                                    <td>Implement energy efficiency programs</td>
                                </tr>
                                <tr>
                                    <td>Reporting</td>
                                    <td>Air pollutant emissions</td>
                                    <td>Reduce air pollutant emissions</td>
                                </tr>
                                <tr>
                                    <td rowSpan="2">Social</td>
                                    <td>Low injury rate</td>
                                    <td rowSpan="2">Negative social and environmental impact on local communities</td>
                                    <td rowSpan="2">Implement programs for social support of indigenous peoples and local communities</td>
                                </tr>
                                <tr>
                                    <td>Employee social support</td>
                                </tr>
                                <tr>
                                    <td rowSpan="2">Governance</td>
                                    <td>Compliance with corporate procedures</td>
                                    <td>Gender imbalance on the board of directors</td>
                                    <td rowSpan="2">Extend ESG risk assessment to all suppliers (458 out of 2350 suppliers were reviewed in 2020). Address gender imbalance on the board of directors.</td>
                                </tr>
                                <tr>
                                    <td>Stakeholder engagement</td>
                                    <td>Insufficient ESG risk assessment</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Recommendations;
