import successImg from "../../../assets/icons/success.svg";
import errorImg from "../../../assets/icons/error.svg";
import closeImg from "../../../assets/icons/close-modal.svg";

const Notification = ({ setPopup, setNotifications, notifications, ...note }) => {
    const onCloseClick = (id) => {
        setNotifications(notifications.filter(notif => notif.id !== id))
    }

    return (
        <div className={note.type === "success" ? "notification-item success" : "notification-item failure"}>
            {note.type === "success" ? 
                <div className="notification-item__left"> 
                    <img src={note.type === "success" ? successImg : errorImg} alt="notification icon" />
                    {note.type === "failure" ?
                        <div className="notification-item__failure">
                            <p>{note.title}</p>
                            <span>{note.text}</span>
                        </div>
                    : 
                    <p>{note.title}</p>
                    }
                </div>
            :
                <>
                    <img src={note.type === "success" ? successImg : errorImg} alt="notification icon" />
                    {note.type === "failure" ?
                        <div className="notification-item__failure">
                            <p>{note.title}</p>
                            <span>{note.text}</span>
                        </div>
                    : 
                        <p>{note.title}</p>
                    }
                </>
            }

            <img src={closeImg} className="notification-item__close" onClick={() => onCloseClick(note.id)} alt="close notification icon" />
        </div>
    )
}

export default Notification;
