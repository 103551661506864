import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {useRecoilValue} from "recoil";

import {createTheme, ThemeProvider} from '@mui/material/styles';
import "./scss/App.scss";

import AuthGuard from "./components/auth/AuthGuard";
import GuestGuard from "./components/auth/GuestGuard";
import Login from "./components/login/Login";
import PageLayout from "./components/layout/page-layout/PageLayout";
import Company from "./components/layout/reports/Company";
import Contractor from "./components/layout/reports/Contractor";
import Contractors from "./components/layout/contractors/Contractors";
import Results from "./components/layout/search/Results";
import NotFound from "./components/maintenance/NotFound";
import Loading from "./components/maintenance/Loading";

import {themeStateSelector} from "./states/session";

const EmptyPage = () => {
    return <></>
}

const App = () => {
    const themeMode = useRecoilValue(themeStateSelector)
    const theme = createTheme({
        palette: {
            mode: themeMode,
        },
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/signin" element={
                        <GuestGuard children={<Login/>}/>
                    }/>
                    <Route element={
                        <AuthGuard children={<PageLayout/>}/>
                    }>
                        <Route path="/" element={<EmptyPage/>}/>
                        <Route path="/report/:id" element={<Company/>}/>
                        <Route path="/contractor/:id" element={<Contractor/>}/>
                        <Route path="/contractors/:id" element={<Contractors/>}/>
                        <Route path="/search" element={<Results/>}/>
                        <Route path="/404" element={<NotFound/>}/>
                        <Route path="/loading" element={<Loading/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
