import {useRecoilValue} from "recoil";
import {Navigate} from "react-router-dom";
import {isLoggedState} from "../../states/session"

const GuestGuard = ({children}) => {
    const isLoggedIn = useRecoilValue(isLoggedState);
    if (isLoggedIn) {
        return <Navigate to="/"/>;
    }
    return (
        <>
            {children}
        </>
    );
};

export default GuestGuard;
