import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {Outlet} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import "./PageLayout.scss";
import Navigation from "../navigation/header-nav/Navigation";
import LeftToolbar from "../navigation/toolbar/LeftToolbar";
import {navigationsList} from "../../../states/navigation";
import rpc from "../../../services/rpc";
import Loading from "../../maintenance/Loading";
import {getOwnUserFromToken, isExpiring} from "../../../services/session";
import {isLoggedSelector, userState} from "../../../states/session";
import {restoreFromStorage} from "../../../services/storage";

const tokenCheckTimeout = 10  // Seconds

const PageLayout = () => {
    const { pathname } = useLocation();
    const {t} = useTranslation('common');
    const { id } = useParams();
    
    const setNavigations = useSetRecoilState(navigationsList)
    const setIsLoggedIn = useSetRecoilState(isLoggedSelector);
    const setUser = useSetRecoilState(userState);

    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [route, setRoute] = useState({ 
        to: pathname,
        from: pathname,
    });
    
    useEffect(()=> {
        setRoute((prev)=> ({to: pathname, from: prev.to }) )
    }, [pathname]);

    // Fetch navigation menu.
    useEffect(() => {
        const getNavigations = async() => {
            const navs = await rpc.getNavigations();
            setNavigations(navs)
        };
        getNavigations();
        setIsLoading(false)
    }, [setNavigations]);

    // Execute background job for access token refresh.
    useEffect(()=> {
        const checkAndRefreshToken = async () => {
            try {
                const currentToken = restoreFromStorage("token")
                if (!isExpiring(currentToken)) {
                    return
                }
                const { token } = await rpc.refreshToken()
                setIsLoggedIn(token)

                const user = getOwnUserFromToken()
                setUser(user)

            } catch (err) {
                // eslint-disable-next-line no-console
                // console.log("ERROR", err)
                setIsLoggedIn(false)
            }
        }
        const interval = setInterval(checkAndRefreshToken, 1000 * tokenCheckTimeout);
        return () => clearInterval(interval);
    }, [setIsLoggedIn, setUser]);

    if (isLoading) {
        return <Loading/>
    }

    return (
        <>
            <Navigation route={route} notifications={notifications} setNotifications={setNotifications} />
            <div className={pathname !== `/contractors/${id}` ? "frame" : null}>
                <div className={pathname !== `/contractors/${id}` ? "frame__sidebar" : null}>
                    <LeftToolbar route={route} notifications={notifications} setNotifications={setNotifications} />
                </div>
                <div className={pathname !== `/contractors/${id}` ? "frame__content" : null}>
                    <Outlet context={[notifications, setNotifications]} />
                </div>
            </div>
            <div className="footer">
                <span>© 2022 Ecodisclosure. {t("All rights reserved")}</span>
            </div>
        </>
    )
}

export default PageLayout;
