import React from "react"; 
import "./Modal.scss";

const Modal = ({ uploadActive, setUploadActive, requestActive, setRequestActive, addNewsActive, setAddNewsActive, children }) => {
    const closeModal = () => {
        if(uploadActive) {
            setUploadActive(false);
        } else if (requestActive) {
            setRequestActive(false);
        } else if (addNewsActive) {
            setAddNewsActive(false);
        }
    }

    return (
        <div className={uploadActive || requestActive || addNewsActive ? "modal active" : "modal"} onClick={closeModal}>
            <div className={uploadActive ? "modal-content active upload" : requestActive ? "modal-content active request" : addNewsActive ? "modal-content active add-news" : "modal-content"} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal;
