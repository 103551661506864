import {useRecoilValue} from "recoil";
import {Navigate} from "react-router-dom";
import {isLoggedState} from "../../states/session"

const AuthGuard = ({children}) => {
    const isLoggedIn = useRecoilValue(isLoggedState);
    if (!isLoggedIn) {
        return <Navigate to="/signin"/>;
    }
    return (
        <>
            {children}
        </>
    );
};

export default AuthGuard;
