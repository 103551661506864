import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Table from "./table/Table";

import rpc from "../../../services/rpc";

const Contractors = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [contractors, setContractors] = useState([])

    useEffect(() => {
        const getContractors = async (id) => {
            try {
                const results = await rpc.getContractors(id)
                setContractors(results)
                setIsLoading(false)
            } catch (e) {
                navigate("/404")
            }
        }
        getContractors(id)
    }, [id, setContractors, navigate]);

    return (
        <Table rows={contractors} loading={isLoading} />
    )
}

export default Contractors;
