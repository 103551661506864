import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import Roboto from "../../assets/fonts/Roboto.ttf";

import logo from "../../assets/img/report-logo.png";

Font.register({ family: "Roboto", src: Roboto });

const styles = StyleSheet.create({
  page: {
    color: "#0B0B0B",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  header: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "120px",
  },
  logo: {
    boxShadow: 0,
    textAlign: "center",
  },
  socialContainer: {
    marginTop: "18px",
    marginLeft: "4%",
    marginRight: "4%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  socialTitle: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "22px",
  },
  socialInfo: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    marginRight: "15px",
  },
  socialParagraph: {
    marginBottom: "20px",
  },
  socialParagraphTitle: {
    paddingBottom: "7px",
  },
  socialParagraphIndicators: {
    marginBottom: "10px",
    color: "#A1A5AE",
  },
  socialParagraphData: {
    color: "#0B0B0B",
  },
  socialCards: {
    display: "flex",
    flexDirection: "column",
    width: "38%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: "40px",
    marginLeft: "6px",
  },
  socialCard: {
    borderRadius: "8px",
    width: "216px",
    height: "96px",
    marginBottom: "24px",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  greenCard: {
    backgroundColor: "#00C614",
  },
  redCard: {
    backgroundColor: "#FF0000",
  },
  yellowCard: {
    backgroundColor: "#FFB800",
  },
  indexTitle: {
    margin: "16px",
    marginBottom: "13px",
  },
  index: {
    fontSize: "16px",
    marginLeft: "16px",
    marginBottom: "16px",
  },
  indexText: {
    fontSize: "12px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    color: "#A1A5AE",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pagesLeft: {
    color: "#0B0B0B",
  },
});

const SocialPage = ({ company }) => {
  return (
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.image} src={logo} />
      </View>
      <View style={styles.socialContainer}>
        <View style={styles.socialInfo}>
          <Text style={styles.socialTitle}>S - SOCIAL</Text>
          <View style={styles.socialParagraph}>
            <Text style={styles.socialParagraphTitle}>General</Text>
            <Text style={styles.socialParagraphIndicators}>
              Employee social support
              <Text style={styles.socialParagraphData}> 9/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Discrimination issues
              <Text style={styles.socialParagraphData}> 6/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Gender equality
              <Text style={styles.socialParagraphData}> 7/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Diversity and inclusivity
              <Text style={styles.socialParagraphData}> 5/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Staff turnover rate
              <Text style={styles.socialParagraphData}> 0.2</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Occupational health and safety
              <Text style={styles.socialParagraphData}> 10/10</Text>
            </Text>
          </View>
          <View style={styles.socialParagraph}>
            <Text style={styles.socialParagraphTitle}>Injuries</Text>
            <Text style={styles.socialParagraphIndicators}>
              Injury level assessment
              <Text style={styles.socialParagraphData}> 9/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Fatal injury rate
              <Text style={styles.socialParagraphData}> 0</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Severe injury rate
              <Text style={styles.socialParagraphData}> 0.1</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Frequency of accidents rate
              <Text style={styles.socialParagraphData}> 0.01</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Frequency of injuries resulting in temporary disability rate
              <Text style={styles.socialParagraphData}> 0.01</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Occupational illness rate
              <Text style={styles.socialParagraphData}> 0.01</Text>
            </Text>
          </View>
          <View style={styles.socialParagraph}>
            <Text style={styles.socialParagraphTitle}>Human Rights</Text>
            <Text style={styles.socialParagraphIndicators}>
              Human rights and ethical business policy in place
              <Text style={styles.socialParagraphData}> yes</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Collaboration with socially responsible suppliers
              <Text style={styles.socialParagraphData}> 7/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Human rights (including child labor and forced labor prohibition)
              <Text style={styles.socialParagraphData}> 10/10</Text>
            </Text>
          </View>
          <View style={styles.socialParagraph}>
            <Text style={styles.socialParagraphTitle}>
              Indigenous Peoples and Local Communities
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Engagement with indigenous peoples and local communities
              <Text style={styles.socialParagraphData}> 6/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Assessment of negative social and environmental impact on local
              communities<Text style={styles.socialParagraphData}> 5/10</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Local community development programs in place
              <Text style={styles.socialParagraphData}> yes</Text>
            </Text>
            <Text style={styles.socialParagraphIndicators}>
              Media coverage of negative social impact not present
              <Text style={styles.socialParagraphData}> absent</Text>
            </Text>
          </View>
        </View>
        <View style={styles.socialCards}>
          <View style={[styles.socialCard, styles.greenCard]}>
            <Text style={styles.indexTitle}>Social Disclosure Index (SDI)</Text>
            <Text style={styles.index}>
              89<Text style={styles.indexText}> high</Text>
            </Text>
          </View>
          <View style={[styles.socialCard, styles.redCard]}>
            <Text style={styles.indexTitle}>Industry Risk Index (IRI)</Text>
            <Text style={styles.index}>
              15<Text style={styles.indexText}> low</Text>
            </Text>
          </View>
          <View style={[styles.socialCard, styles.yellowCard]}>
            <Text style={styles.indexTitle}>Social Impact Index (SII)</Text>
            <Text style={styles.index}>
              78.6<Text style={styles.indexText}> moderate</Text>
            </Text>
          </View>
          <View style={styles.socialParagraph}>
            <Text style={styles.socialParagraphTitle}>Other Risk Factors</Text>
            <Text style={styles.socialParagraphIndicators}>
              Presence of legal cases related to violations in the area of
              <Text style={styles.socialParagraphData}> none</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>
          © 2022 ECODISCLOSURE. Not for further distribution, for internal use
          only.
        </Text>
        <Text style={styles.pagesLeft}>3/4</Text>
      </View>
    </Page>
  );
};

export default SocialPage;
