import { atom, selector } from "recoil";
import {getInitialLoggedInState, getOwnUserFromToken} from "../services/session";
import {restoreFromStorage, writeToStorage} from "../services/storage";


export const userState = atom({
    key: "userState",
    default: getOwnUserFromToken(),
})

export const isLoggedState = atom({
    key: "isLoggedState",
    default: getInitialLoggedInState(),
})

export const isLoggedSelector = selector({
    key: "isLoggedSelector",
    get: ({get}) => get(isLoggedState),
    set: ({set}, newValue) => {
        if (!newValue) {
            // Reset token on logout
            writeToStorage("token", null);
            set(isLoggedState, false);
        } else {
            // Set token on login or refresh
            writeToStorage("token", newValue);
            set(isLoggedState, true);
        }
        const user = getOwnUserFromToken()
        set(userState, user);
    },
});


export const themeState = atom({
    key: "themeState",
    default: restoreFromStorage("theme", "light"),
})

export const themeStateSelector = selector({
    key: "themeStateSelector",
    get: ({get}) => get(themeState),
    set: ({set}, newValue) => {
        writeToStorage("theme", newValue)
        set(themeState, newValue);
    },
});
