import {useState} from 'react';
import "./FilterBar.scss";
import "../../page-layout/PageLayout.scss";
import close from "../../../../assets/icons/close-modal.svg";
import arrowDown from "../../../../assets/img/chevron-down.png";
import arrowUp from "../../../../assets/img/chevron-up.png";
import range from "../../../../assets/icons/range.svg";
import Range from "./range/Range";
import {DatePicker} from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import {formatDate} from "../../../../services/date";
import {useTranslation} from "react-i18next";

const FilterBar = (props) => {
    const {t} = useTranslation('common');
    const { setShowFilter, rows, setInnerRows, setError, error }  = props; 

    const [rangeValues, setRangeValues] = useState({fromEnv: "", toEnv: "", fromSocial: "", toSocial: "", fromGover:"", toGover: ""});
    const [industryIsClicked, setIndustryIsClicked] = useState(false);
    const [regionIsClicked, setRegionIsClicked] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState();
    const [selectedRegion, setSelectedRegion] = useState();
    const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 7));
    const [endDate, setEndDate] = useState(new Date().setDate(new Date().getDate()));

    console.log()

    // function timestampToDate(date) {
    //     let d = new Date();
    //     d.setTime(date);
    //     return ('0' + (d.getMonth() + 1)).slice(-2) + '.' + ('0' + d.getDate()).slice(-2) + '.' + d.getFullYear();
    // }

    const handleInputChange = (e) => {
        switch(e.target.className) {
            case "fromEnv": setRangeValues({...rangeValues, fromEnv : Number(e.target.value)});
            break;
            case "toEnv": setRangeValues({...rangeValues, toEnv : Number(e.target.value)});
            break;
            case "fromSocial" : setRangeValues({...rangeValues, fromSocial : Number(e.target.value)});
            break;
            case "toSocial" : setRangeValues({...rangeValues, toSocial : Number(e.target.value)});
            break;
            case "fromGover" : setRangeValues({...rangeValues, fromGover : Number(e.target.value)});
            break;
            case "toGover" : setRangeValues({...rangeValues, toGover : Number(e.target.value)});
            break;
        }
    };

    const showIndustryMenu = () => {
        setRegionIsClicked(false); 
        setIndustryIsClicked(true); 
    }

    const showRegionMenu = () => {
        setIndustryIsClicked(false); 
        setRegionIsClicked(true); 
    }

    const onIndustryOptionClick = (idx) => {
        const filteredIndustry = rows.filter(row => row?.id === idx);
        setSelectedIndustry(filteredIndustry);
        setIndustryIsClicked(false);
    }

    const onRegionOptionClick = (idx) => {
        const filteredRegion = rows.filter(row => row?.id === idx);
        setSelectedRegion(filteredRegion);
        setRegionIsClicked(false); 
    }

    const handleStartDate = (date) => {
        let datetime = new Date(date);
        let formatted_date = datetime.getTime(); 
        setStartDate(formatDate(formatted_date));
    }

    const handleEndDate = (date) => {
        let datetime = new Date(date);
        let formatted_date = datetime.getTime(); 
        setEndDate(formatDate(formatted_date));
    }

    const formatRowDate = (date) => {
        if (date) {
            let datetime = new Date(date);
            let formatted_date = formatDate(datetime.getTime()); 
            return formatted_date;
        } else {
            return false; 
        }
    }

    const filterContractor = () => {
        const filteredRows = rows.filter(row => (selectedIndustry[0]?.industry === row?.industry) && (selectedRegion[0].address?.slice(7, 17) === row?.address?.slice(7, 17)) && (row?.report?.environmental > rangeValues?.toEnv && row?.report?.environmental < rangeValues?.fromEnv) && (row?.report?.social > rangeValues?.toSocial && row?.report?.social < rangeValues?.fromSocial) && (row?.report?.governance > rangeValues?.toGover && row?.report?.governance < rangeValues?.fromGover) && (formatRowDate(row?.report?.published_at) > startDate) && (formatRowDate(row?.report?.published_at) < endDate)); 

        setInnerRows(filteredRows);
    }

    const validateFields = () => {
        if (rangeValues && (rangeValues.fromEnv && rangeValues.toEnv && rangeValues.fromSocial && rangeValues.toSocial && rangeValues.fromGover && rangeValues.toGover) && selectedIndustry !== t("Industry") && selectedRegion !== t("Region")) {
            if ((rangeValues.fromEnv < 1 || rangeValues.fromEnv > 100) || (rangeValues.toEnv < 1 || rangeValues.toEnv > 100) || (rangeValues.fromSocial < 1 || rangeValues.fromSocial > 100) || (rangeValues.toSocial < 1 || rangeValues.toSocial > 100) || (rangeValues.fromGover < 1 || rangeValues.fromGover > 100) || (rangeValues.toGover < 1 || rangeValues.toGover > 100)) {
                setError(t("Numbers must be in the range from 1 to 100")); 
            } else {
                filterContractor();
                setError(""); 
            }
        } else {
            setError(t("Fill in all of the necessary fields")); 
        }
    }

    const clearFields = () => {
        setRangeValues({fromEnv: "", toEnv: "", fromSocial: "", toSocial: "", fromGover:"", toGover: ""});
        setSelectedIndustry("");
        setSelectedRegion("");
        setError(""); 
    }

    const closeFilter = () => {
        setShowFilter(false);
        setError(""); 
    }

    const selectMenu = () => {

    }

    return (
        <div className="filter">
            <div className="filter__head">
                <h3 className="filter__title">{t("Filters")}</h3>
                <img src={close} alt="close button" className="filter__close" onClick={closeFilter} />
            </div>
            <div className="filter__main">
                {industryIsClicked ? 
                    <>
                        <div className="filter__menu" onClick={() => setIndustryIsClicked(false)} >
                            <p>{!selectedIndustry ? t("Industry") : selectedIndustry[0].industry}</p>
                            <img src={arrowUp} alt="arrow up" />
                        </div>
                        <div className="filter__options">
                            {rows.map(row => {
                                return <div className="filter__option" onClick={() => onIndustryOptionClick(row.id)} key={row.id}>{row?.industry}</div>
                            })}
                        </div>
                    </>
                : 
                    <div className="filter__menu" onClick={showIndustryMenu}>
                        <p>{!selectedIndustry ? t("Industry") : selectedIndustry[0]?.industry}</p>
                        <img src={arrowDown} alt="arrow down" />
                    </div>
                }
                {regionIsClicked ?
                    <>
                        <div className="filter__menu" onClick={() => setRegionIsClicked(false)}>
                            <p>{!selectedRegion ? t("Region") : selectedRegion[0].address?.slice(7, 17)}</p>
                            <img src={arrowUp} alt="arrow up" />
                        </div>
                        <div className="filter__options regions">
                            {rows.map(row => {
                                return <div className="filter__option" onClick={() => onRegionOptionClick(row.id)} key={row.id}>{row?.address?.slice(7, 17)}</div>
                            })}
                        </div>
                    </>
                :
                    <div className="filter__menu" onClick={showRegionMenu}>
                        <p>{!selectedRegion ? t("Region") : selectedRegion[0].address?.slice(7, 17)}</p>
                        <img src={arrowDown} alt="arrow down" />
                    </div>
                }
                <div className="filter__range-block">
                    <p>Environmental</p>
                    <Range value={[rangeValues.fromEnv, rangeValues.toEnv]} handleInputChange={handleInputChange} className={["toEnv", "fromEnv"]}>
                    </Range>
                </div>
                <div className="filter__range-block">
                    <p>Social</p>
                    <Range value={[rangeValues.fromSocial, rangeValues.toSocial]} handleInputChange={handleInputChange} className={["toSocial", "fromSocial"]} />
                </div>
                <div className="filter__range-block">
                    <p>Governance</p>
                    <Range value={[rangeValues.fromGover, rangeValues.toGover]} handleInputChange={handleInputChange} className={["toGover", "fromGover"]} />
                </div>
                <div className="filter__range-block">
                    <p>{t("Last check")}</p>
                    <div className="range-fields">
                        <DatePicker dateFormat="DD.MM.YYYY" initialDate={startDate} onChange={handleStartDate} />
                            <img src={range} alt="range line" className="range-fields__icon" />
                        <DatePicker dateFormat="DD.MM.YYYY" initialDate={endDate} onChange={handleEndDate} />
                    </div>
                </div>
                {error ?
                    <p className="error-text">{error}</p>
                :
                    null
                }
            </div>
            <div className="filter__footer">
                <div className="filter__clear-btn" onClick={() => clearFields()}>{t("Clear")}</div>
                <div className="filter__apply-btn btn-f is-blue" onClick={() => validateFields()}>{t("Apply")}</div>
            </div>
        </div>
    );
}

export default FilterBar; 
