import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";
import NoData from "./no-data/NoData";
import {formatDate} from "../../../../services/date";
import rpc from "../../../../services/rpc";

const Feed = () => {
    const { id } = useParams(); // Company ID.
    const {t} = useTranslation('common');

    const [isLoading, setIsLoading] = useState(true)
    const [feed, setFeed] = useState([])
    useEffect(() => {
        const getFeed = async (id) => {
            try{
                const {feed} = await rpc.getFeed(id)
                setFeed(feed)
            } catch (e) {
                // TODO: pop alert?
            }
            setIsLoading(false)
        }
        getFeed(id)
    }, [id, setFeed]);

    if (isLoading) {
        return (
            <NoData>
                <p className="no-data__title">{t("There are no news yet")}</p>
                <p>{t("you may want to add a piece of news")}</p>
            </NoData>
        );
    }
    return (
        <>
            {(feed.length > 0)?
                <>
                    {feed.map((item) => {
                        return (
                            <div className="doc-list is-news" key={`fragment_${item.id}`}>
                                <div className="doc-list__item is-flex is-green">
                                    <div className="doc-list__stat icon-1e_stat"></div>
                                    <div className="doc-list__info">
                                        <div className="doc-list__name">{item.content}</div>
                                        <div className="doc-list__date">{formatDate(item.date)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>:
                    <NoData>
                        <p className="no-data__title">{t("There are no news yet")}</p>
                        <p>{t("you may want to add a piece of news")}</p>
                    </NoData>
            }
        </>
    )
}

export default Feed;
