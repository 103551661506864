import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import Roboto from "../../assets/fonts/Roboto.ttf";

import logo from "../../assets/img/report-logo.png";
import { formatDate } from "../../services/date";
import EnvironmentalPage from "./EnvironmentalPage";
import SocialPage from "./SocialPage";
import GovernancePage from "./GovernancePage";
import ReportTable from "./ReportTable";

Font.register({ family: "Roboto", src: Roboto });

const styles = StyleSheet.create({
  page: {
    color: "#0B0B0B",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  container: {
    marginLeft: "8%",
    marginRight: "7%",
  },
  header: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "120px",
  },
  logo: {
    boxShadow: 0,
    textAlign: "center",
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
    fontSize: "12px",
    color: "#A1A5AE",
    marginRight: "30px",
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
    fontSize: "12px",
    color: "#A1A5AE",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    color: "#A1A5AE",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pagesLeft: {
    color: "#0B0B0B",
  },
  pageTitle: {
    fontSize: "16px",
    color: "#0B0B0B",
    marginTop: "18px",
  },
  companyData: {
    color: "#0B0B0B",
  },
  text: {
    marginBottom: "12px",
  },
});

const DownloadableReport = ({ company }) => {
  const title = `${formatDate(company?.report?.published_at)}-${company?.name}-ESG-report.pdf`;

  return (
    <Document
      title={title}
      author={"corporate@ecodisclosure.com"}
      creator={"corporate@ecodisclosure.com"}
      producer={"corporate@ecodisclosure.com"}
      subject={"ESG audit report"}
      language={"RU"}
    >
      <Page style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Image style={styles.image} src={logo} />
          </View>
          <Text style={[styles.pageTitle, styles.text]}>{company.name}</Text>
          <View style={styles.columns}>
            <View style={styles.leftColumn}>
              <Text style={styles.text}>
                Report date{" "}
                <Text style={styles.companyData}>
                  {formatDate(company?.report?.published_at)}
                </Text>
              </Text>
              <Text style={styles.text}>
                Industry{" "}
                <Text style={styles.companyData}>{company.industry}</Text>
              </Text>
              <Text style={styles.text}>
                Address{" "}
                <Text style={styles.companyData}>{company.address}</Text>
              </Text>
              <Text>
                Registration date{" "}
                <Text style={styles.companyData}>
                  {formatDate(company.registration_date)}
                </Text>
              </Text>
            </View>
            <View style={styles.rightColumn}>
              <Text style={styles.text}>
                OGRN <Text style={styles.companyData}>{company.ogrn}</Text>
              </Text>
              <Text style={styles.text}>
                OKPO <Text style={styles.companyData}>{company.okpo}</Text>
              </Text>
              <Text style={styles.text}>
                INN <Text style={styles.companyData}>{company.inn}</Text>
              </Text>
              <Text>
                Number of employees{" "}
                <Text style={styles.companyData}>
                  {company.employees_number}
                </Text>
              </Text>
            </View>
          </View>
          <ReportTable company={company} />
        </View>
        <View style={styles.footer}>
          <Text>
            © 2022 ECODISCLOSURE. This information is for internal use only and
            may not be further distributed.
          </Text>
          <Text style={styles.pagesLeft}>1/4</Text>
        </View>
      </Page>
      <EnvironmentalPage company={company} />
      <SocialPage company={company} />
      <GovernancePage company={company} />
    </Document>
  );
};

export default DownloadableReport;
