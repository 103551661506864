import {useState} from "react"; 
import {useTranslation} from "react-i18next";
import "./LanguageSwitcher.scss";
import ArrowDown from "../../../assets/img/chevron-down.png";
import ArrowUp from "../../../assets/img/chevron-up.png";

const LanguageSwitcher = () => {
    const [arrowIsClicked, setArrowIsClicked] = useState(false); 
    const [language, setLanguage] = useState("RU");
    const [hovered, setHovered] = useState(false);

    const {i18n} = useTranslation("navigations");

    const handleChange = (event) => {
        if (event.target.value === "en") {
            setLanguage("EN");
            i18n.changeLanguage("en");
            setHovered(false);
        } else if (event.target.value === "ru") {
            setLanguage("RU");
            i18n.changeLanguage("ru");
            setHovered(false);
        }
    };

    const onDropDownClick = () => {
        setArrowIsClicked(!arrowIsClicked);
    }

    return (
        <div className="lang-dropdown" onClick={onDropDownClick} onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
            {arrowIsClicked? 
                (<>
                    <div className="lang-dropdown__btn">{language}<img src={ArrowUp} alt="arrow up" className={hovered ? "lang-dropdown__hovered" : null} /></div>
                    <div className="lang-dropdown__body">
                        <button className="option option-ru" value={"ru"} onClick={handleChange}>RU</button>
                        <button className="option option-en" value={"en"} onClick={handleChange}>ENG</button>
                    </div>
                </>
                )
            : 
                (<div className="lang-dropdown" onClick={onDropDownClick}>
                    <div className="lang-dropdown__btn">{language}<img src={ArrowDown} alt="arrow down" className={hovered ? "lang-dropdown__hovered" : null} /></div>
                </div>)
            }
        </div>
    );
}

export default LanguageSwitcher; 
