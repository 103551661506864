
export const restoreFromStorage = (item, fallback) => {
    if (!item) {
        // eslint-disable-next-line no-throw-literal
        throw "invalid local storage key";
    }
    const value = window.localStorage.getItem(item);
    if (!value) {
        return fallback
    }
    return value
}

export const writeToStorage = (key, value) => {
    if (!key) {
        console.log("try to write empty key", key, value)
        return
    }
    if (value) {
        window.localStorage.setItem(key, value);
    } else {
        window.localStorage.removeItem(key);
    }
}