import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Tooltip from "@mui/material/Tooltip";
import "./Table.scss";
import "../../page-layout/PageLayout.scss";
import FilterIcon from "../../../../assets/img/filter-icon.svg";
import PrevBtn from "../../../../assets/icons/prev-arrow.svg";
import NextBtn from "../../../../assets/icons/next-arrow.svg";
import {formatDate} from "../../../../services/date";
import Filters from "../filter-bar/FilterBar";
import TableHeader from "./TableHeaders";
import useSort from "./hooks/useSort"; 
import usePagination from "./hooks/usePagination";

const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const Table = ({ rows, loading }) => {
    const navigate = useNavigate();
    const {t} = useTranslation('common');

    const [searchText, setSearchText] = useState("");
    const [innerRows, setInnerRows] = useState(rows);
    const [searchResult, setSearchResult] = useState(rows); 
    const [showFilter, setShowFilter] = useState(false);
    const [error, setError] = useState(""); 

    useEffect(() => {
        setInnerRows(rows);
    }, [rows]);

    useEffect(() => {
        setInnerRows(innerRows);
        
    }, [innerRows]);

    const { sortedArray, requestSort, sortConfig } = useSort(innerRows);
    const { currentlyOnPage, pages, page, switchPage } = usePagination(sortedArray);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);

        const searchRegex = new RegExp(escapeRegExp(searchText), "i");
        const filteredRows = rows.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field]) return searchRegex.test(row[field].toString());
                return false;
            });
        });
        setSearchResult(filteredRows);

        if(!searchText) {
            setSearchResult(rows);
        }
    };

    const navigateToContractor = (index) => {
        navigate(`/contractor/${index}`);
    }

    return (
            <div className="contractors">
                <div className="contractors__main">
                    <h2 className="contractors__title">{t("List of contractors")}</h2>
                    <div className="contractors__controls">
                        <div className="contractors__controls_left">
                            <img src={FilterIcon} alt="filter icon" className={showFilter? "contractors__icon active-filter" : "contractors__icon"} onClick={() => setShowFilter(true)} />
                            <input type="text" placeholder={t("Search by contractor's name...")} onChange={(event) => requestSearch(event.target.value)}></input>
                        </div>
                        <div className="contractors__controls_right">
                            <ReactHTMLTableToExcel 
                                id="table-to-xls"
                                className="contractors__btn btn-f is-blue icon-1a_down"
                                table="table"
                                filename="contractors-table"
                                sheet="contractors-table"
                                buttonText={t("Download the table")}
                            />
                        </div>
                    </div>
                    <table className="contractors-table" id="table">
                        <thead align="left" cellSpacing="0" className="contractors-table__head">
                            <tr>
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="id" name="№"/>
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="name" name={t("Contractor")}/>
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="environmental" name="Environmental"/>
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="social" name="Social" />
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="governance" name="Governance" />
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="industry" name={t("Industry")} />
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="region" name={t("Region")} />
                                <TableHeader requestSort={requestSort} sortConfig={sortConfig} sortBy="published_at" name={t("Last check")} />
                            </tr>
                        </thead>
                        <tbody className="contractors-table__body">
                            {searchResult.length !== 1 ?
                                currentlyOnPage?.map(item => {
                                    return (
                                        <Tooltip key={item.id} title={t("Double click to see the report")} placement="top-start">
                                            <tr onDoubleClick={() => navigateToContractor(item.id)}>
                                                <td>{item.id - 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item?.report?.environmental ? item.report.environmental : "-"}</td>
                                                <td>{item?.report?.social ? item?.report?.social : "—"}</td>
                                                <td>{item?.report?.governance ? item?.report?.governance : "—"}</td>
                                                <td>{item?.industry ? item?.industry : "—"}</td>
                                                <td>{item?.address ? item?.address.slice(7, 17) : "—"}</td>
                                                <td>{item?.report?.published_at ? formatDate(item?.report?.published_at) : "—"}</td>
                                            </tr>
                                        </Tooltip>
                                    )})
                            :
                                <tr>
                                    <td>{searchResult[0]?.id}</td>
                                    <td>{searchResult[0]?.name}</td>
                                    <td>{searchResult[0]?.report?.environmental ? searchResult[0].report.environmental : "—"}</td>
                                    <td>{searchResult[0]?.report?.social ? searchResult[0]?.report?.social : "—"}</td>
                                    <td>{searchResult[0]?.report?.governance ? searchResult[0]?.report?.governance : "—"}</td>
                                    <td>{searchResult[0]?.industry ? searchResult[0]?.industry : "—"}</td>
                                    <td>{searchResult[0]?.address ? searchResult[0]?.address.slice(7, 17) : "—"}</td>
                                    <td>{searchResult[0]?.report?.published_at ? formatDate(searchResult[0]?.report?.published_at) : "—"}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="contractors__pagination pagination">
                    <img src={PrevBtn} alt="prev button arrow" />
                    <div className="pagination__btns">
                        {pages.map(item => {
                            return <button className={page===item ? "pagination__btn active" : "pagination__btn"} key={item} onClick={() => switchPage(item)}>{item}</button>
                        })}
                    </div>
                    <img src={NextBtn} alt="next button arrow" />
                </div>
                {showFilter?
                    <Filters setShowFilter={setShowFilter} rows={rows} setInnerRows={setInnerRows} setError={setError} error={error} />
                :
                    null
                }
            </div>
    )
}

export default Table;
