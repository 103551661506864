import { useState } from "react";

const usePagination = (items) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(7);
    const lastOnPage = page * rowsPerPage;
    const firstOnPage = lastOnPage - rowsPerPage;
    const currentlyOnPage = items.slice(firstOnPage, lastOnPage);
    const numberOfPages = Math.ceil(items.length / rowsPerPage);
    
    const pages = [];
    for (let i = 1; i <= numberOfPages; i++) {
        pages.push(i);
    }

    const switchPage = (id) => {
        console.log(id)
        setPage(id);
    }  

    return { currentlyOnPage, pages, page, switchPage };
}

export default usePagination;
