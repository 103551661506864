import "./NoData.scss";

const NoData = ({ children }) => {
    return (
        <div className="no-data">
            {children}
        </div>
    )
}
export default NoData;
