import ReactLoading from "react-loading";

import Grid from '@mui/material/Grid';


const Loading = () => {
    let color = 'gray';
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '50vh'}}
        >
            <Grid item xs={3}>
                <ReactLoading
                    type={"cubes"}
                    color={color}
                />
            </Grid>
        </Grid>
    )
}

export default Loading;