import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Table from "../contractors/table/Table";

import rpc from "../../../services/rpc";

const Results = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [contractors, setContractors] = useState([])

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const getSearchResults = async (query) => {
            if (!query) {
                return []
            }
            const results = await rpc.getSearchResults(query)
            setContractors(results)
            setIsLoading(false)
            return results
        }
        getSearchResults(searchParams.get("q"))
    }, [setContractors, searchParams]);

    return (
        <Table rows={contractors} loading={isLoading} />
    )
}

export default Results;
