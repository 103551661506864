import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import Roboto from "../../assets/fonts/Roboto.ttf";

Font.register({ family: "Roboto", src: Roboto });

const styles = StyleSheet.create({
  tableContainer: {
    color: "#0B0B0B",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  esg: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    marginTop: "20px",
  },
  esgRating: {
    width: "168px",
    height: "60px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "grey",
  },
  redIndex: {
    backgroundColor: "rgba(255, 0, 0, 0.64)",
  },
  yellowIndex: {
    backgroundColor: "rgba(255, 184, 0, 0.64)",
  },
  greenIndex: {
    backgroundColor: "rgba(0, 198, 20, 0.64)",
  },
  companyRating: {
    fontSize: "16px",
    paddingTop: "12px",
  },
  ratingTableCaption: {
    width: "504px",
    height: "40px",
    backgroundColor: "#d9dbdf",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "16px",
  },
  criteriaRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  criteriaColumn: {
    display: "flex",
    flexDirection: "column",
    width: "168px",
    height: "70px",
    border: "1px solid #d9dbdf",
    padding: "10px 14px",
  },
  criteriaMiddleColumns: {
    height: "140px",
    borderRight: 0,
    borderLeft: 0,
  },
  criteriaRightColumn: {
    height: "140px",
  },
  clearBorderTop: {
    borderTop: 0,
  },
  clearBorderLeftRight: {
    borderLeft: 0,
    borderRight: 0,
  },
  borderTopRightRadius: {
    borderTopRightRadius: "7px",
  },
  borderTopLeftRadius: {
    borderTopLeftRadius: "7px",
  },
  borderBottomRightRadius: {
    borderBottomRightRadius: "7px",
  },
  borderBottomLeftRadius: {
    borderBottomLeftRadius: "7px",
  },
});

const ReportTable = ({ company }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.esg}>
        {company?.report?.environmental <= 39.9 ? (
          <View
            style={[
              styles.esgRating,
              styles.borderTopLeftRadius,
              styles.redIndex,
            ]}
          >
            <Text>E - environmental</Text>
            <Text style={styles.companyRating}>
              {company?.report?.environmental}
            </Text>
          </View>
        ) : company?.report?.environmental >= 70 ? (
          <View
            style={[
              styles.esgRating,
              styles.borderTopLeftRadius,
              styles.greenIndex,
            ]}
          >
            <Text>E - environmental</Text>
            <Text style={styles.companyRating}>
              {company?.report?.environmental}
            </Text>
          </View>
        ) : (
          <View
            style={[
              styles.esgRating,
              styles.borderTopLeftRadius,
              styles.yellowIndex,
            ]}
          >
            <Text>E - environmental</Text>
            <Text style={styles.companyRating}>
              {company?.report?.environmental}
            </Text>
          </View>
        )}
        {company?.report?.social <= 39.9 ? (
          <View style={[styles.esgRating, styles.redIndex]}>
            <Text>S - social</Text>
            <Text style={styles.companyRating}>{company?.report?.social}</Text>
          </View>
        ) : company?.report?.social >= 70 ? (
          <View style={[styles.esgRating, styles.greenIndex]}>
            <Text>S - social</Text>
            <Text style={styles.companyRating}>{company?.report?.social}</Text>
          </View>
        ) : (
          <View style={[styles.esgRating, styles.yellowIndex]}>
            <Text>S - social</Text>
            <Text style={styles.companyRating}>{company?.report?.social}</Text>
          </View>
        )}
        {company?.report?.governance <= 39.9 ? (
          <View
            style={[
              styles.esgRating,
              styles.borderTopRightRadius,
              styles.redIndex,
            ]}
          >
            <Text>G - governance</Text>
            <Text style={styles.companyRating}>
              {company?.report?.governance}
            </Text>
          </View>
        ) : company?.report?.governance >= 70 ? (
          <View
            style={[
              styles.esgRating,
              styles.borderTopRightRadius,
              styles.greenIndex,
            ]}
          >
            <Text>G - governance</Text>
            <Text style={styles.companyRating}>
              {company?.report?.governance}
            </Text>
          </View>
        ) : (
          <View
            style={[
              styles.esgRating,
              styles.borderTopRightRadius,
              styles.yellowIndex,
            ]}
          >
            <Text>G - governance</Text>
            <Text style={styles.companyRating}>
              {company?.report?.governance}
            </Text>
          </View>
        )}
      </View>
      <View style={styles.ratingTableCaption}>
        <Text>POSITIVE ASPECTS</Text>
      </View>
      <View style={styles.criteriaRow}>
        <View>
          <View style={styles.criteriaColumn}>
            <Text>Environmental management</Text>
          </View>
          <View style={[styles.criteriaColumn, styles.clearBorderTop]}>
            <Text>Reporting</Text>
          </View>
        </View>
        <View>
          <View style={[styles.criteriaColumn, styles.clearBorderLeftRight]}>
            <Text>Low level of injuries</Text>
          </View>
          <View
            style={[
              styles.criteriaColumn,
              styles.clearBorderTop,
              styles.clearBorderLeftRight,
            ]}
          >
            <Text>Employee support</Text>
          </View>
        </View>
        <View>
          <View style={styles.criteriaColumn}>
            <Text>Compliance with corporate procedures</Text>
          </View>
          <View style={[styles.criteriaColumn, styles.clearBorderTop]}>
            <Text>Stakeholder engagement</Text>
          </View>
        </View>
      </View>

      <View style={styles.ratingTableCaption}>
        <Text>NEGATIVE ASPECTS</Text>
      </View>
      <View style={styles.criteriaRow}>
        <View>
          <View style={styles.criteriaColumn}>
            <Text>Energy efficiency</Text>
          </View>
          <View style={[styles.criteriaColumn, styles.clearBorderTop]}>
            <Text>Emissions of pollutants into the atmosphere</Text>
          </View>
        </View>
        <View>
          <View style={[styles.criteriaColumn, styles.criteriaMiddleColumns]}>
            <Text>
              Negative social and environmental impact on local communities
            </Text>
          </View>
        </View>
        <View>
          <View style={styles.criteriaColumn}>
            <Text>Lack of women on the board of directors</Text>
          </View>
          <View style={[styles.criteriaColumn, styles.clearBorderTop]}>
            <Text>Insufficient assessment of ESG risks</Text>
          </View>
        </View>
      </View>

      <View style={styles.ratingTableCaption}>
        <Text>RECOMENDATIONS</Text>
      </View>
      <View style={styles.criteriaRow}>
        <View>
          <View style={styles.criteriaColumn}>
            <Text>Implement energy efficiency programs</Text>
          </View>
          <View
            style={[
              styles.criteriaColumn,
              styles.clearBorderTop,
              styles.borderBottomLeftRadius,
            ]}
          >
            <Text>Reduce emissions of pollutants into the atmosphere</Text>
          </View>
        </View>
        <View>
          <View style={[styles.criteriaColumn, styles.criteriaMiddleColumns]}>
            <Text>
              Implement programs to provide social support for indigenous
              peoples and local communities
            </Text>
          </View>
        </View>
        <View>
          <View
            style={[
              styles.criteriaColumn,
              styles.criteriaRightColumn,
              styles.borderBottomRightRadius,
            ]}
          >
            <Text>
              Expand the assessment of ESG risks to all suppliers (458 out of
              2350 suppliers were assessed in 2020). Correct gender imbalance on
              the board of directors.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ReportTable;
