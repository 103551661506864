import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useOutletContext } from "react-router-dom";
import rpc from "../../../services/rpc";
import Loading from "../../maintenance/Loading";
import AuditHistory from "../../../assets/img/audithistorybig.png";
import Documents from "./details/documents/Documents";
import "../reports/details/documents/Documents.scss";
import Feed from "./details/Feed";
import Modal from "../modals/Modal";
import RequestModal from "../modals/request-modal/RequestModal";
import "../modals/Modal.scss";
import Recommendations from "./details/recommendations/Recommendations";
import "../page-layout/PageLayout.scss";
import "./Contractor.scss";
import Notification from "../popup/Notification";
import '../popup/Notification.scss';

const Contractor = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation('common');

    const [notifications, setNotifications] = useOutletContext();
    const [isLoading, setIsLoading] = useState(true);
    const [contractor, setContractor] = useState({});
    const [requestActive, setRequestActive] = useState(false); 

    useEffect(() => {
        const getContractor = async (id) => {
            try {
                const {contractor} = await rpc.getContractor(id)
                setContractor(contractor)
                setIsLoading(false)
            } catch (e) {
                navigate("/404")
            }
        }
        getContractor(id)

    }, [id, setContractor, navigate]);

    if (isLoading) {
        return <Loading/>
    }

    return (
        <>
            <div className="rage">
                <div className="rage__list is-flex">
                    <div className={contractor.report?.environmental <= 39.9 ? "rage__item is-red" : contractor.report?.environmental >= 70 ? "rage__item is-green" : "rage__item is-yellow"}>
                        <div className="rage__name">Environmental</div>
                        <div className="rage__val"><span className="rage__type-1 ">{contractor.report?.environmental} /</span><span className="rage__type-2">100</span></div>
                    </div>
                    <div className={contractor.report?.social <= 39.9 ? "rage__item is-red" : contractor.report?.social >= 70 ? "rage__item is-green" : "rage__item is-yellow"}>
                        <div className="rage__name">SOCIAL</div>
                        <div className="rage__val"><span className="rage__type-1">{contractor.report?.social} /</span><span className="rage__type-2">100</span></div>
                    </div>
                    <div className={contractor.report?.governance <= 39.9 ? "rage__item is-red" : contractor.report?.governance >= 70 ? "rage__item is-green" : "rage__item is-yellow"}>
                        <div className="rage__name">GOVERNANCE</div>
                        <div className="rage__val"><span className="rage__type-1">{contractor.report?.governance} /</span><span className="rage__type-2">100</span></div>
                    </div>
                </div>
            </div>
            {notifications.length > 0 ? 
                <div className="notification-wrapper">
                    {notifications.map(note => {
                        return <Notification key={note.id} {...note} setNotifications={setNotifications} notifications={notifications} />
                    })}
                </div>
            :
                null
            }
            <div className="flex-block">
                <div className="flex-block__block">
                    <div className="block-stat large">
                        <div className="block-stat__head is-flex is-space">
                            <div className="block-stat__title">{t("Audit history")}</div>
                            <div className="block-stat__select">
                                <div className="select">
                                    <div className="select__title icon-1i_arrow">2021</div>
                                </div>
                            </div>
                        </div>
                        <div className="grafic">
                            {/* <AuditHistory history={contractor.reports_history} /> */}
                            <img src={AuditHistory} alt="Audit" className="audit__history__big"/>
                        </div>
                    </div>
                </div>
            </div>
            <Recommendations />
            <div className="flex-block">
                        <div className="flex-block__block">
                            <div className="block-stat block-stat_medium">
                                <div className="block-stat__head is-flex is-space">
                                    <div className="block-stat__title">{t("Documents")}</div>
                                    <div className="block-stat__btn-link icon-1b_up" onClick={() => setRequestActive(true)}>{t("Request documents")}</div>
                                    <Modal requestActive={requestActive} setRequestActive={setRequestActive}>
                                        <RequestModal setRequestActive={setRequestActive} notifications={notifications} setNotifications={setNotifications} />
                                    </Modal>
                                </div>
                                <Documents />
                            </div>
                        </div>
                <div className="flex-block__block">
                    <div className="block-stat block-stat_medium">
                        <div className="block-stat__head is-flex is-space">
                            <div className="block-stat__title">{t("News")}</div>
                        </div>
                        <Feed />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contractor;
