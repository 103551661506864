import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import NoData from "../no-data/NoData";
import rpc from "../../../../../services/rpc";
import {formatDate} from "../../../../../services/date";
import "./Documents.scss";

const Documents = () => {
    const { id } = useParams(); // Company ID.
    const {t} = useTranslation('common');

    const [isLoading, setIsLoading] = useState(true)
    const [documents, setDocuments] = useState([])
    const [isButtonClicked, setButtonClicked] = useState({})

    useEffect(() => {
        const getDocuments = async (id) => {
            const {documents} = await rpc.getDocuments(id)
            setDocuments(documents)

            let keyFlags = {}
            for (let i=0; i < documents.length; i++) {
                const item = documents[i]
                const key = item.key
                keyFlags[key] = false
            }
            setButtonClicked(keyFlags)
            setIsLoading(false)
        }
        getDocuments(id)
    }, [id, setDocuments, documents]);

    const downloadDocument = (href) => {
        window.open(href, "_blank");
    }

    const deleteDocument = (id, key) => {
        return async () => {
            isButtonClicked[key] = true
            try {
                const { result } = await rpc.deleteDocument(id, key); 
                if (result === "ok") {
                    delete isButtonClicked[key]
                    const clearedDocuments = documents.filter(doc => doc.key !== key);
                    setDocuments(clearedDocuments);
                }
            } catch {
                isButtonClicked[key] = true
            }
        }
    }

    if (isLoading) {
        return (
            <NoData>
                <p className="no-data__title">{t("There are no documents yet")}</p>
                <p>{t("you may want to add a document")}</p>
            </NoData>
        );
    }

    

    return (
        <>
            {(documents.length > 0)?
                <div className="doc-list">
                    {documents.map((item) => {
                        return (
                            <div className="doc-list__item is-flex" key={`fragment_${item.id}`}>
                                <div className="doc-list__info">
                                    <div className="doc-list__name">{item.title}</div>
                                    <div className="doc-list__date">{t("uploaded")} {`${formatDate(item.date)}`}</div>
                                </div>
                                <div className="doc-list__control">
                                    <div className="doc-list__btn icon-1a_down" onClick={()=>downloadDocument(item.href)}></div>
                                    <IconButton
                                        onClick={deleteDocument(id, item.key)}
                                    >  
                                        {isButtonClicked[item.key] ? <DeleteIcon disabled /> : <DeleteIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <NoData>
                    <p className="no-data__title">{t("There are no documents yet")}</p>
                    <p>{t("you may want to add a document")}</p>
                </NoData>
            }
        </>
    )
}

export default Documents;
