import {useState} from "react";
import {useParams} from "react-router-dom";
import "./RequestModal.scss";
import {useTranslation} from "react-i18next";
import close from "../../../../assets/icons/close-modal.svg";
import ArrowDown from "../../../../assets/img/chevron-down.png";
import ArrowUp from "../../../../assets/img/chevron-up.png";
import {customAlphabet} from 'nanoid';

import rpc from "../../../../services/rpc";

const Request = ({ setRequestActive, setNotifications, notifications }) => {
    const { id } = useParams(); 
    const {t} = useTranslation(['common', 'documents']);
    const nanoid = customAlphabet('1234567890', 10);

    const defaultDocuments = [
        "Charter documents",
        "Organizational structure of the company",
        "Dynamics of the number of personnel (by category of personnel) for the last three completed financial years",
        "Description of the company's activities with a description of the list of the company's activities, technologies used, raw materials, etc.",
        "Company risk map",
        "Valid permissions to engage in certain types of activities (license) or a certificate of admission to a certain type of work",
        "Documents confirming the right of use (ownership) of forest and water objects",
        "Non-financial report (sustainability report/social report) or integrated report for the last 3 (three) years",
        "Environmental Protection Strategy or Policy / Environmental policy",
        "Policies or other documents related to the management of water resources, emissions into the atmosphere, land use, in the field of biodiversity conservation",
        "Policies and other regulatory documents related to climate change adaptation",
        "Report and other documents on greenhouse gas emissions",
        "Responsible supply chain policy and/or other documents confirming the existence of environmental requirements for contractors/suppliers",
        "Policies or programs aimed at minimizing waste generation",
        "Documents containing provisions confirming that the analysis of the investment object, along with financial indicators, takes into account the factors of sustainable development and the risks associated with them",
        "Documents regulating occupational safety and industrial safety",
        "Copies of ISO 14001 and ISO 50001 certificates and other certificates of compliance with environmental protection, management and energy efficiency standards",
        "Data on gender balance and gender policy",
        "Charity and volunteering policy",
        "Human rights protection policy",
        "Policy on interaction with the population leading a traditional way of life (for example, indigenous peoples, local communities)",
        "Documents confirming participation in national and international initiatives/associations/voluntary standards (e.g. PRI, CDP, GRI, UN GС, SC, ASC, etc.)/expert councils/working groups in the field of sustainable development",
        "Corporate Governance Code (Principles)",
        "Documents regulating the activities of the General Meeting of Shareholders",
        "Documents regulating the activities of the Board of Directors",
        "Documents regulating the activities of the committees of the Board of Directors",
        "Documents regulating the risk management system, description of risk management procedures",
        "Documents regulating the internal control system, description of internal control procedures",
        "Documents regulating the information security system, description of information security procedures"
    ]

    const [arrowIsClicked, setArrowIsClicked] = useState(false); 
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [checkboxValues, setCheckboxValues] = useState(defaultDocuments.map((item) => {
            let obj = {}; 
            obj.id = Number(nanoid());
            obj.document = item;
            obj.checked = false;
            return obj;
    }));
    const [requesting, setRequesting] = useState(false);

    const handleRequest = async () => {
        setRequesting(true)
        try {
            let documents = selectedOptions.slice();
            await rpc.requestDocuments(id, documents);
            setRequesting(false);
            setSelectedOptions([]);
            const newNotification = {
                id: Number(nanoid()),
                title: selectedOptions.length > 1 ? t("Documents have been requested") : t("Document has been requested"),
                type: "success"
            };
            setNotifications([...notifications, newNotification]);
        } catch (e) {
            setRequesting(false)
            console.log(e)
            const newNotification = {
                id: Number(nanoid()),
                title: t("Failed to request"),
                text:  selectedOptions.length > 1 ? t("An error occured while requesting the documents, please try again") : t("An error occured while requesting the document, please try again"),
                type: "failure"
            };
            setNotifications([...notifications, newNotification]);
        }
    }

    const handleChange = (event) => {
        const newArr = checkboxValues.map((value) => (
            
            {
            ...value,
            checked: value.id == event.target.id ? !value.checked : value.checked
        }));

        setCheckboxValues(newArr)

        const checked = newArr.filter(item => item.checked === true)
        setSelectedOptions(checked)
    }

    return (
        <div className="modal__container">
            <div className="modal__header">
                <h2>{t("Request Documents")}</h2>
                <img className="close-icon" onClick={() => setRequestActive(false)} src={close} alt="close button" />
            </div>
            <div className="request-content">
                <div className="request-content__drop-down request-dd">
                    {arrowIsClicked? 
                            <>
                                {selectedOptions.length === 0 ?
                                    <div className="request-dd__head drop-down__head input-area">
                                        <p>{t("Document type")}</p>
                                        <span className="drop-down__arrow" onClick={() => setArrowIsClicked(false)}>
                                            <img src={ArrowUp} alt="arrow up" />
                                        </span>
                                    </div>
                                    :
                                    <div className="request-dd__head drop-down__head input-area">
                                        <p className="document">{selectedOptions.length === 1 ? `${t("You have selected")} ${selectedOptions.length} ${t("document")}` : `${t("You have selected")} ${selectedOptions.length} ${t("documents")}`}
                                        </p>
                                        <span className="drop-down__arrow" onClick={() => setArrowIsClicked(false)}>
                                            <img src={ArrowUp} alt="arrow up" />
                                        </span>
                                    </div>
                                }
                                <div className="request-dd__body drop-down__body">
                                    {checkboxValues.map((item) => {
                                        return <div className="request-dd__option" key={item.id}>
                                                    <input type="checkbox" id={item.id} value={item.document} onClick={handleChange} defaultChecked={item.checked} />
                                                    <label htmlFor="checkbox">{t(item.document, {ns: 'documents'})}</label>
                                                </div>
                                    })}
                                </div>
                            </>
                        :
                        <>
                            {selectedOptions.length > 0 ? 
                                <div className="request-dd__head drop-down__head input-area">
                                    <p>{selectedOptions.length === 1 ? `${t("You have selected")} ${selectedOptions.length} ${t("document")}` : `${t("You have selected")} ${selectedOptions.length} ${t("documents")}`}</p>
                                    <span className="drop-down__arrow" onClick={() => setArrowIsClicked(true)}>
                                        <img src={ArrowDown} alt="arrow down" />
                                    </span>
                                </div>
                                :
                                <div className="request-dd__head drop-down__head input-area">
                                    <p>{t("Document type")}</p>
                                    <span className="drop-down__arrow" onClick={() => setArrowIsClicked(true)}>
                                        <img src={ArrowDown} alt="arrow down" />
                                    </span>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className="request-content__text-area">
                    <textarea type="text" placeholder={t("In case you have not found the required document in the list, please enter its name")} />
                </div>
            </div>
            <div className="modal__footer">
                <div className="cancel" onClick={() => setRequestActive(false)}>{t("Cancel")}</div>
                <div className="request-btn btn-f is-blue" onClick={handleRequest}>{requesting? "Loading..." : t("Request")}</div>
            </div>
        </div>
    );
}

export default Request;
