import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {useRecoilValue} from "recoil";
import {navigationsList} from "../../../../states/navigation";
import DownloadableReport from "../../../report/DownloadableReport";
import "./LeftToolbar.scss";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {formatDate} from "../../../../services/date";
import {useTranslation} from "react-i18next";
import rpc from "../../../../services/rpc";
import SelectCompany from "./SelectCompany";
import {customAlphabet} from 'nanoid';

const LeftToolbar = ({ notifications, setNotifications }) => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const {t} = useTranslation('common');
    const navigations = useRecoilValue(navigationsList);
    const navigate = useNavigate();
    const nanoid = customAlphabet('1234567890', 10);
    
    const [chosenCompany, setChosenCompany] = useState(pathname.split("/")[2]);
    const [companyData, setCompanyData] = useState({});
    const [selectedCompany, setSelectedCompany] = useState(navigations[0]?.name); 

    useEffect(()=>{
        // After login we redirect on /, but we don't have index page.
        // Then we choose first link in navigation menu.
        if (pathname==="/" && navigations.length > 0){
            const defaultPage = navigations[0].children[0].href
            setChosenCompany(defaultPage)
            navigate(defaultPage)
        } 

        if(!selectedCompany) {
            const defaultPageName = navigations[0]?.name;
            setSelectedCompany(defaultPageName);
        }
    }, [navigations, navigate, pathname, selectedCompany, chosenCompany])

    useEffect(()=>{
        const getCompany = async (id) => {
            try {
                const {company} = await rpc.getCompany(id)
                setCompanyData(company)
            } catch(e) {

            }
        }
        getCompany(id);
    }, [setCompanyData, id]);

    const onDownload = () => {
        const newNotification = {
            id: Number(nanoid()),
            title: t("Report has been downloaded"),
            type: "success"
        };
        setNotifications([...notifications, newNotification]);
    }

    return ( 
        <>
            {pathname !== `/contractors/${id}` ?
            <div className="frame__sidebar">
                <div className="toolbar-container">
                    <div className="sidebar">
                        <SelectCompany setChosenCompany={setChosenCompany} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
                        {!companyData? 
                            <>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("Industry")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("OGRN")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("INN")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("OKPO")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("Registration date")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("Address")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("Employees number")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{t("No data")}</div>
                                    <div className="sidebar__text _type-3">{t("Last check")}</div>
                                </div>
                            </>
                        :
                            <>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.industry}</div>
                                    <div className="sidebar__text _type-3">{t("Industry")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.ogrn}</div>
                                    <div className="sidebar__text _type-3">{t("OGRN")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.inn}</div>
                                    <div className="sidebar__text _type-3">{t("INN")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.okpo}</div>
                                    <div className="sidebar__text _type-3">{t("OKPO")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.registration_date}</div>
                                    <div className="sidebar__text _type-3">{t("Registration date")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.address}</div>
                                    <div className="sidebar__text _type-3">{t("Address")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{companyData?.employees_number}</div>
                                    <div className="sidebar__text _type-3">{t("Employees number")}</div>
                                </div>
                                <div className="sidebar__text-wr">
                                    <div className="sidebar__text _type-2">{(companyData?.report?.created_at)?.substr(0, 10)}</div>
                                    <div className="sidebar__text _type-3">{t("Last check")}</div>
                                </div>
                            </>
                        }
                        <a className="sidebar__btn-1 btn-f is-blue-trans" href="https://ecodisclosure.com/request-en" target="_blank" rel="noreferrer">{t("Request Check")}</a>

                        <PDFDownloadLink style={{ textDecoration: 'none' }}
                            document={<DownloadableReport company={companyData} />}
                            fileName={`${formatDate(companyData?.report?.published_at)}-${companyData?.name}-ESG-report.pdf`}
                        >
                        <div className="sidebar__btn-2 btn-f is-blue icon-1a_down" onClick={onDownload}>{t("Download report")}</div>
                        </PDFDownloadLink>
                    </div>
                </div>
                </div>
            :
                null
            }
        </>
    )
}

export default LeftToolbar;
