import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useOutletContext } from "react-router-dom";
import Loading from "../../maintenance/Loading";
import Rating from "./details/rating/Rating";
import AuditHistory from "../../../assets/img/audithistory.png";
import AuditProgress from "../../../assets/img/auditstatus.png";
import RatingTable from "./details/RatingTable";
import Documents from "./details/documents/Documents";
import Feed from "./details/Feed";
import Modal from "../modals/Modal";
import UploadModal from "../modals/upload-modal/UploadModal";
import NewsModal from "../modals/news-modal/NewsModal";
import Notification from "../popup/Notification";
import '../popup/Notification.scss';
import "../../../scss/PageBlock.scss";
import "../page-layout/PageLayout.scss";
import "./details/audit/Audit.scss";
import rpc from "../../../services/rpc";


const Company = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [uploadActive, setUploadActive] = useState(false); 
    const [addNewsActive, setAddNewsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [notifications, setNotifications] = useOutletContext();

    useEffect(() => {
        const getCompany = async (id) => {
            try {
                const {company} = await rpc.getCompany(id)
                setCompany(company)
                setIsLoading(false)
            } catch (e) {
                navigate("/404")
            }
        }
        getCompany(id)
    }, [id, setCompany, navigate]);

    const {t} = useTranslation('common');

    if (isLoading) {
        return <Loading/>
    }

    return (
        <>
            <div className="page-title">{t("Contractors overall rating")}</div>
            <div className="rage">
                <div className="rage__list is-flex">
                    <Rating name= {"environmental"} rating={
                        (company.report !== null)? company.report.environmental: "-"
                    }/>
                    <Rating name= {"social"} rating={
                        (company.report !== null)? company.report.social: "-"
                    }/>
                    <Rating name= {"governance"} rating={
                        (company.report !== null)? company.report.governance: "-"
                    }/>
                </div>
            </div>
            {notifications.length > 0 ? 
                <div className="notification-wrapper">
                    {notifications.map(note => {
                        return <Notification key={note.id} {...note} setNotifications={setNotifications} notifications={notifications} />
                    })}
                </div>
            :
                null
            }
            <div className="flex-block">
                <div className="flex-block__block">
                    <div className="block-stat block-stat_long">
                        <div className="block-stat__head is-flex is-space">
                            <div className="block-stat__title">{t("Audit history")}</div>
                            <div className="block-stat__select">
                                <div className="select">
                                    <div className="select__title icon-1i_arrow">2022</div>
                                </div>
                            </div>
                        </div>
                        <div className="grafic">
                            {/* <AuditHistory history={company.reports_history}/> */}
                            <img src={AuditHistory} alt="Audit" className="audit__history"/>
                        </div>
                    </div>
                </div>
                <div className="flex-block__block">
                    <div className="block-stat block-stat_small">
                        <div className="block-stat__head">
                            <div className="block-stat__title">{t("Contragent audit progress")}</div>
                        </div>
                        <div className="block-stat__grafic">
                            <div className="grafic">
                                {/* <AuditProgress progress={company.audit_progress}/> */}
                                <img src={AuditProgress} alt="Audit" className="audit__progress"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-block">
                <div className="flex-block__block">
                    <div className="block-stat is-up block-stat_medium">
                        <div className="block-stat__head is-flex">
                            <div className="block-stat__title">{t("Top best contractors")}</div>
                            <div className="tab-block is-flex">
                            <div className="tab-block__btn is-active">{t("All")}</div>
                            <div className="tab-block__btn">environmental</div>
                            <div className="tab-block__btn">social</div>
                            <div className="tab-block__btn">governance</div>
                            </div>
                        </div>
                        <RatingTable rows={company.top3} />
                    </div>
                </div>
                <div className="flex-block__block">
                    <div className="block-stat is-down block-stat_medium">
                        <div className="block-stat__head is-flex">
                            <div className="block-stat__title">{t("Top worst contractors")}</div>
                            <div className="tab-block is-flex">
                            <div className="tab-block__btn is-active">{t("All")}</div>
                            <div className="tab-block__btn">environmental</div>
                            <div className="tab-block__btn">social</div>
                            <div className="tab-block__btn">governance</div>
                            </div>
                        </div>
                        <RatingTable rows={company.bottom3} />
                    </div>
                </div>
            </div>
            <div className="flex-block">
                <div className="flex-block__block">
                    <div className="block-stat block-stat_medium">
                        <div className="block-stat__head is-flex is-space">
                            <div className="block-stat__title">{t("Documents")}</div>
                            <div className="block-stat__btn-link icon-1b_up" onClick={() => setUploadActive(true)}>{t("Upload Documents")}</div>
                            <Modal uploadActive={uploadActive} setUploadActive={setUploadActive}>
                                <UploadModal setUploadActive={setUploadActive} setNotifications={setNotifications} notifications={notifications} />
                            </Modal>
                        </div>
                        <Documents />
                    </div>
                </div>
                <div className="flex-block__block">
                        <div className="block-stat block-stat_medium">
                            <div className="block-stat__head is-flex is-space">
                                <div className="block-stat__title">{t("News")}</div>
                                <div className="block-stat__btn-link icon-1c_plus" onClick={() => setAddNewsActive(true)}>{t("Upload News")}</div>
                                <Modal addNewsActive={addNewsActive} setAddNewsActive={setAddNewsActive}>
                                    <NewsModal addNewsActive={addNewsActive} setAddNewsActive={setAddNewsActive} />
                                </Modal>
                            </div>
                            <Feed />
                        </div>
                </div>
            </div>
        </>
    )
}

export default Company;
