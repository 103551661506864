import {useTranslation} from "react-i18next";
import range from "../../../../../assets/icons/range.svg";

const Range = (props) => {
    const {t} = useTranslation('common');

    return (
        <div className="range-fields">
            <input type="number" 
                    value={props.value[1] === 0 ? "" : props.value[1]} placeholder={t("From")} 
                    onChange={props.handleInputChange} 
                    className={props.className[0]}>
            </input>
            <img src={range} 
                alt="range line" 
                className="range-fields__icon" 
            />
            <input type="number" 
                    value={props.value[0] === 0 ? "" : props.value[0]} 
                    placeholder={t("To")} 
                    onChange={props.handleInputChange} 
                    className={props.className[1]}>
            </input>
        </div>
    );
}

export default Range; 
