import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {navigationsList} from "../../../../states/navigation";
import {useRecoilValue} from "recoil";
import ArrowDown from "../../../../assets/img/chevron-down.png";
import ArrowUp from "../../../../assets/img/chevron-up.png";
import "./SelectCompany.scss";

const SelectCompany = ({ setChosenCompany, setSelectedCompany, selectedCompany }) => {
    const navigations = useRecoilValue(navigationsList);
    const navigate = useNavigate();

    const [arrowIsClicked, setArrowIsClicked] = useState(false); 
    const [onHover, setOnHover] = useState(false);

    const onDropDownClick = () => {
        setArrowIsClicked(!arrowIsClicked);
    }

    const handleChange = (event) => {
        navigations.map((item) => {
            if (event.target.value === item.id) {
                setSelectedCompany(item.name);
            }
                setChosenCompany(event.target.value);
                navigate(navigations[event.target.value-1].children[0].href);
                setOnHover(false);
        })
    }



    return (
        <>
            {arrowIsClicked? 
                <div className="select-wrapper" onClick={onDropDownClick} onMouseOver={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}>
                    <div className={onHover? "button select-btn hovered" : "button select-btn"}>
                        {selectedCompany}
                        <img src={ArrowUp} alt="arrow up" className={onHover? "hovered arrow" : "arrow"} />
                    </div>
                    <div className="select-content">
                        {navigations.map((group) => (
                            <button className="select-option" key={group.id} value={`${group.id}`} onClick={handleChange}>{`${group.name}`}</button>
                        ))}
                    </div>
                </div>
            : 
                <div className="select-wrapper" onClick={onDropDownClick} onMouseOver={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <div className={onHover? "button select-btn hovered" : "button select-btn"} value={selectedCompany}>
                        {selectedCompany}
                        <img src={ArrowDown} alt="arrow down" className={onHover? "hovered arrow" : "arrow"}/>
                    </div>
                </div>
            }
        </>
    );
}

export default SelectCompany;
