import { useState } from "react"; 
import "./Table.scss";
import sortUp from "../../../../assets/img/chevron-up.png";
import sortDown from "../../../../assets/img/chevron-down.png";
import Tooltip from '@mui/material/Tooltip';
import {useTranslation} from "react-i18next";

const TableHeader = (props) => {
    const {t} = useTranslation('common');
    const [showSort, setShowSort] = useState(false); 

    return (
        <Tooltip title={t("Sort")} placement="top-start">
            <th onMouseOver={() => setShowSort(true)} onMouseLeave={() => setShowSort(false)}>
                {showSort ? 
                    <div className="sorting-container">
                        <button onClick={() => props.requestSort(props.sortBy)}>{props.name}</button>
                        <div className="sorting-container__icons">
                            <img src={sortUp} className={props.sortConfig?.direction === "descending" ? "active" : null} alt="sort ascending" />
                            <img src={sortDown} className={props.sortConfig?.direction === "ascending" ? "active" : null} alt="sort descending" />
                        </div>
                    </div>
                : 
                    <button onClick={() => props.requestSort(props.sortBy)}>{props.name}</button>
                }
            </th>
        </Tooltip>
    );
}

export default TableHeader; 
