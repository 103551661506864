import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import Roboto from "../../assets/fonts/Roboto.ttf";

import logo from "../../assets/img/report-logo.png";

Font.register({ family: "Roboto", src: Roboto });

const styles = StyleSheet.create({
  page: {
    color: "#0B0B0B",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  header: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "120px",
  },
  logo: {
    boxShadow: 0,
    textAlign: "center",
  },
  environmentalContainer: {
    marginTop: "18px",
    marginLeft: "4%",
    marginRight: "4%",
    display: "flex",
    flexDirection: "row",
  },
  environmentalTitle: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "22px",
  },
  environmentalInfo: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    marginRight: "15px",
  },
  environmentalParagraph: {
    marginBottom: "20px",
  },
  paragraphTitle: {
    paddingBottom: "7px",
  },
  paragraphIndicators: {
    marginBottom: "6px",
    color: "#A1A5AE",
  },
  paragraphScore: {
    color: "#0B0B0B",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    color: "#A1A5AE",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pagesLeft: {
    color: "#0B0B0B",
  },
  environmentalCards: {
    display: "flex",
    flexDirection: "column",
    width: "38%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: "40px",
    marginLeft: "6px",
  },
  environmentalCard: {
    borderRadius: "8px",
    width: "216px",
    height: "96px",
    marginBottom: "24px",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  indexTitle: {
    margin: "16px",
    marginBottom: "13px",
  },
  index: {
    fontSize: "16px",
    marginLeft: "16px",
    marginBottom: "16px",
  },
  indexText: {
    fontSize: "12px",
  },
  greenCard: {
    backgroundColor: "#00C614",
  },
  redCard: {
    backgroundColor: "#FF0000",
  },
  yellowCard: {
    backgroundColor: "#FFB800",
  },
});

const EnvironmentalPage = ({ company }) => {
  return (
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.image} src={logo} />
      </View>
      <View style={styles.environmentalContainer}>
        <View style={styles.environmentalInfo}>
          <Text style={styles.environmentalTitle}>E - ENVIRONMENTAL</Text>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Reporting</Text>
            <Text style={styles.paragraphIndicators}>
              Submission of reports to authorized bodies
              <Text style={styles.paragraphScore}> 10/10</Text>
            </Text>
            <Text>
              Reports submitted, environmental charges paid, no violations in
              the last 3 years
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Environmental Management</Text>
            <Text style={styles.paragraphIndicators}>
              Assessment of company's environmental openness
              <Text style={styles.paragraphScore}> 6/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Assessment of environmental management
              <Text style={styles.paragraphScore}> 7/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Sustainable development and corporate social responsibility
              programs<Text style={styles.paragraphScore}> 8/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Working with responsible suppliers/supplier checks
              <Text style={styles.paragraphScore}> 8/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              "Green office"<Text style={styles.paragraphScore}> 9/10</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Energy Efficiency</Text>
            <Text style={styles.paragraphIndicators}>
              Implementation of energy efficiency programs
              <Text style={styles.paragraphScore}> 4/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Use of renewable energy sources
              <Text style={styles.paragraphScore}> none</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Waste</Text>
            <Text style={styles.paragraphIndicators}>
              Assessment of environmental protection when handling waste
              <Text style={styles.paragraphScore}> 6/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Programs to reduce waste from production
              <Text style={styles.paragraphScore}> 8/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Recycling/regeneration/recovery of waste
              <Text style={styles.paragraphScore}> 5/10</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Water Use</Text>
            <Text style={styles.paragraphIndicators}>
              Assessment of environmental protection and rational use of water
              bodies<Text style={styles.paragraphScore}> 5/10</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Atmospheric Air</Text>
            <Text style={styles.paragraphIndicators}>
              Assessment of atmospheric air protection
              <Text style={styles.paragraphScore}> 6/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Emissions of greenhouse gases and pollutants in tons of CO2
              equivalent<Text style={styles.paragraphScore}> 102,500 t.</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Programs to reduce greenhouse gas emissions
              <Text style={styles.paragraphScore}> 6/10</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Overall rating</Text>
            <Text style={styles.paragraphIndicators}>
              {company.name} has a moderate impact on the environment
            </Text>
          </View>
        </View>
        <View style={styles.environmentalCards}>
          <View style={[styles.environmentalCard, styles.greenCard]}>
            <Text style={styles.indexTitle}>
              Environmental Disclosure Index (EDI)
            </Text>
            <Text style={styles.index}>
              76.6<Text style={styles.indexText}> high</Text>
            </Text>
          </View>
          <View style={[styles.environmentalCard, styles.redCard]}>
            <Text style={styles.indexTitle}>Industry Risk Index (IRI)</Text>
            <Text style={styles.index}>
              15<Text style={styles.indexText}> low</Text>
            </Text>
          </View>
          <View style={[styles.environmentalCard, styles.yellowCard]}>
            <Text style={styles.indexTitle}>
              Environmental Impact Index (EII)
            </Text>
            <Text style={styles.index}>
              40.8<Text style={styles.indexText}> moderate</Text>
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>
              Condition of territories where company facilities are located
              <Text style={styles.paragraphScore}> 8/10</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              {company.name} facilities are not located in the Baikal zone
              and/or Protected Natural Areas, and/or Arctic zones, and/or Ramsar
              sites, and/or water protection zones, and/or sea/shelf zones
            </Text>
          </View>
          <View style={styles.environmentalParagraph}>
            <Text style={styles.paragraphTitle}>Other risk factors</Text>
            <Text style={styles.paragraphIndicators}>
              Information on the person in the Federal Register of Persons
              Incurring Environmental Damage (PTO UONVOS) is{" "}
              <Text style={styles.paragraphScore}>absent</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Presence of negative impact on the environment in the media is{" "}
              <Text style={styles.paragraphScore}>absent</Text>
            </Text>
            <Text style={styles.paragraphIndicators}>
              Presence of lawsuits related to violations in the field of ecology
              and nature management is{" "}
              <Text style={styles.paragraphScore}>absent</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>
          © 2022 ECODISCLOSURE. Redistribution prohibited. This information is
          for internal use only.
        </Text>
        <Text style={styles.pagesLeft}>2/4</Text>
      </View>
    </Page>
  );
};

export default EnvironmentalPage;
