import jwtDecode from "jwt-decode";

import {writeToStorage, restoreFromStorage} from "./storage";


export const getInitialLoggedInState = () => {
    const token = restoreFromStorage("token")
    if (verifyToken(token)) {
        writeToStorage(token)
        return true
    }
    writeToStorage(null)  // set empty token to remove old from local storage
    return false
}

export const getOwnUserFromToken = () => {
    const token = restoreFromStorage("token")
    if (verifyToken(token)) {
        return extractUser(token)
    }
    return null
}

export const extractUser = (token) => {
    if (!token) {
        return null;
    }
    const user = jwtDecode(token);
    delete user["expires_at"];
    return user;
}

export const verifyToken = (token) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    const expiresAt =  decoded["expires_at"]
    const now = new Date().toISOString();
    return expiresAt > now;
}

export const isExpiring = (token) => {
    if (!token) {
        return true;
    }
    const decoded = jwtDecode(token);
    const expiresAt =  decoded["expires_at"]
    const threshold = new Date( Date.now() + 1000 * 4* 60 ).toISOString();
    return expiresAt < threshold;
}
