import { useState } from "react";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./UploadModal.scss";
import "../Modal.scss";
import close from "../../../../assets/icons/close-modal.svg";
import deleteFile from "../../../../assets/icons/delete-added-file.svg";
import rpc from "../../../../services/rpc";
import { customAlphabet } from 'nanoid';

const UploadModal = ({ setUploadActive, setNotifications, notifications }) => {
    const MAX_ALLOWED_DOCUMENTS = 10;

    const { id } = useParams(); 
    const {t} = useTranslation('common');
    const nanoid = customAlphabet('1234567890', 10);

    const [drag, setDrag] = useState(false);
    const [files, setFiles] = useState([]); 
    
    const dragStartHandler = (e) => {
        e.preventDefault();
        setDrag(true);
    }

    const dragLeaveHandler = (e) => {
        e.preventDefault();
        setDrag(false);
    }

    const handleUpload = async () => {
        try {
            const {result} = await rpc.uploadDocuments(id, files)
            if (result === "success") {
                setFiles([]);
                const newNotification = {
                    id: Number(nanoid()),
                    title: files.length > 1 ? t("Documents have been uploaded") : t("Document has been uploaded"),
                    type: "success"
                };
                setNotifications([...notifications, newNotification]);
            } 
        } catch (e) {
            const newNotification = {
                id: Number(nanoid()),
                title: t("Failed to upload"),
                text:  files.length > 1 ? t("An error occured while uploading the documents, please try again") : t("An error occured while uploading the document, please try again"),
                type: "failure"
            };
            setNotifications([...notifications, newNotification]);
        }
    }

    const onDropHandler = (event) => {
        event.preventDefault();
        let droppedFiles = [...files, ...event.dataTransfer.files];
        setDrag(false);
        setFiles(droppedFiles);
    }

    const handleAddFilesBtn = (event) => {
        const newFiles = [...files, ...event.target.files];
        if (newFiles && newFiles.length <= MAX_ALLOWED_DOCUMENTS) {
            setFiles(newFiles);
        } else {
            const newNotification = {
                id: Number(nanoid()),
                title: t("Failed to upload"),
                text:  t("You cannot upload more than 10 documents. Please try again"),
                type: "failure"
            };
            setNotifications([...notifications, newNotification]);
        }
    }  

    const deleteSelectedFile = (idx) => {
        let array = [...files];
        if (idx >= 0) {
            array.splice(idx, 1);
            setFiles(array);
        }
    }

    const fileItems = files?.map((file, idx) => {
        return (
            <div className="doc-list" key={idx}>
                <div className="doc-list__item doc is-flex">
                    <div className="doc-list__info">
                        <div className="doc-list__name">{file.name}</div>
                        <div className="doc-list__size">{`${Math.round(file.size * 0.001) + " Кб"}`}</div>
                    </div>
                    <div>
                        <div className="doc-list__btn"><img src={deleteFile} alt="delete button" onClick={() => deleteSelectedFile(idx)} /></div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="modal__container">
                <div className="modal__header">
                    <h2>{t("Adding documents")}</h2>
                    <img className="close-icon" onClick={() => setUploadActive(false)} src={close} alt="close button" />
                </div>
                {files.length > 0
                    ?
                    <div className="selected-docs-area">
                        <div className="selected-docs">
                            {fileItems}
                        </div>
                        <div className={drag? "drag-and-drop active selected-docs-drag" : "drag-and-drop selected-docs-drag"}
                            onDragStart={e => dragStartHandler(e)}
                            onDragLeave={e => dragLeaveHandler(e)}
                            onDragOver={e => dragStartHandler(e)}
                            onDrop={e => onDropHandler(e)}
                        >
                        {drag
                            ? <p>{t("Drop the documents to upload them")}</p>
                            : <>
                                <p>{t("Drag and drop the documents into the dotted area or click the Upload documents button")}</p>
                                <label htmlFor="file" className="btn-f is-blue-trans upload-btn">{t("Upload documents")}</label>
                                <input type="file" id="file" style={{ display: "none" }} onChange={handleAddFilesBtn} multiple />
                            </>
                        }
                        </div>
                    </div>
                    :
                    <div className={drag? "drag-and-drop active" : "drag-and-drop"}
                        onDragStart={e => dragStartHandler(e)}
                        onDragLeave={e => dragLeaveHandler(e)}
                        onDragOver={e => dragStartHandler(e)}
                        onDrop={e => onDropHandler(e)}
                    >
                        {drag
                            ? <p>{t("Drop the documents to upload them")}</p>
                            : <>
                                <p>{t("Drag and drop the documents into the dotted area or click the Upload documents button")}</p>
                                <label htmlFor="file" className="btn-f is-blue-trans upload-btn">{t("Upload documents")}</label>
                                <input type="file" id="file" style={{ display: "none" }} onChange={handleAddFilesBtn} multiple />
                            </>
                        }
                    </div>
                }
                <div className="modal__footer">
                    <div className="cancel-btn" onClick={() => setUploadActive(false)}>{t("Cancel")}</div>
                    <div className="add-btn btn-f is-blue" onClick={handleUpload}>{t("Upload")}</div>
                </div>
            </div>
        </>
    );
}

export default UploadModal;
