import {useState} from "react";
import {useTranslation} from "react-i18next";
import "./NewsModal.scss";
import "../Modal.scss";
import close from "../../../../assets/icons/close-modal.svg";
import ArrowDown from "../../../../assets/img/chevron-down.png";
import ArrowUp from "../../../../assets/img/chevron-up.png";

const NewsModal = ({ setAddNewsActive }) => {
    const [arrowIsClicked, setArrowIsClicked] = useState(false); 
    const [selectedType, setSelectedType] = useState();
    const [inputValue, setInputValue] = useState(); 
    const [textareaValue, setTextareaValue] = useState(); 

    const {t} = useTranslation('common');

    const handleClick = (e) => {
        setSelectedType(e.target.innerText);
        setArrowIsClicked(false);
    }

    const checkInputValue = (e) => {
        setInputValue(e.target.value.length);
    }

    const checkTextareaValue = (e) => {
        setTextareaValue(e.target.value.length);
    }

    const onDeleteInput = () => {
        setInputValue(0);
    }

    const onDeleteTextarea = () => {
        setTextareaValue(0);
    }

    return (
        <>
            <div className="news-modal modal__container">
                <div className="modal__header">
                    <h2>{t("Adding news")}</h2>
                    <img className="close-icon" onClick={() => setAddNewsActive(false)} src={close} alt="close button" />
                </div>
                <div className="news-content">
                    <div className="drop-down">
                        {arrowIsClicked? 
                            <>
                                <div className="drop-down__head news-content__input-area input-area news-content__dd-head">
                                    {selectedType?
                                        <>
                                            <span className="dd-tooltip">{t("Type of score")}</span>
                                            <p className="news-content__selected-type">{selectedType}</p>
                                        </>
                                    :
                                        <p>{t("Type of score")}</p>
                                    }
                                    <span className="drop-down-btn" onClick={() => setArrowIsClicked(false)}>
                                        <img src={ArrowUp} className="drop-down__arrow" alt="arrow up" />
                                    </span>
                                </div>
                                <div className="drop-down__body news-content__dd-body">
                                    <p onClick={handleClick}>{t("Positive")}</p>
                                    <p onClick={handleClick}>{t("Neutral")}</p>
                                    <p onClick={handleClick}>{t("Negative")}</p>
                                </div>
                            </>
                            :
                            <div className="drop-down__head news-content__input-area input-area news-content__dd-head">
                                {selectedType?
                                    <>
                                        <span className="dd-tooltip">{t("Type of score")}</span>
                                        <p className="news-content__selected-type">{selectedType}</p>
                                    </>
                                    :
                                    <p>{t("Type of score")}</p>
                                }
                                <span className="drop-down-btn" onClick={() => setArrowIsClicked(true)}>
                                    <img src={ArrowDown} className="drop-down__arrow" alt="arrow down" />
                                </span>
                            </div>
                        }
                    </div>
                    {inputValue > 0 ?
                        <>  <span className="input-tooltip active">{t("Source reference (public)")}</span>
                            <input className="news-content__input-area input-area" type="text" placeholder={t("Source reference (public)")} onChange={checkInputValue} />
                            <img className="delete-input active" src={close} onClick={onDeleteInput} alt="delete input text" />
                        </>
                    :
                        <input className="news-content__input-area input-area" type="text" placeholder={t("Source reference (public)")} onChange={checkInputValue} />
                    }
                    {textareaValue? 
                        <div className="news-content__text-area">
                            <span className="textarea-tooltip active">{t("Describe the news")}</span>
                            <textarea type="text" placeholder={t("Describe the news")} onChange={checkTextareaValue}/>
                            <img className="delete-textarea active" src={close} onClick={onDeleteTextarea} alt="delete textarea text" />
                        </div>  
                    : 
                        <div className="news-content__text-area">
                            <textarea type="text" placeholder={t("Describe the news")} onChange={checkTextareaValue}/>
                        </div>    
                    }
                </div>
                <div className="modal__footer">
                    <div className="cancel-btn" onClick={() => setAddNewsActive(false)}>{t("Cancel")}</div>
                    <div className="add-btn btn-f is-blue">{t("Add")}</div>
                </div>
            </div>
        </>
    )
}

export default NewsModal;
